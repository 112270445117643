import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { IPreseasonApprovalApiResponse } from '../preSeasonInterfaces';

export const initPreSeasonApprovalThunk = createAsyncThunk('preSeason/initApproval', async (_, { rejectWithValue }) => {
  const response = await executeApiRequest<IPreseasonApprovalApiResponse>(() =>
    httpService.get(`/Preseason/InitApproval`)
  );

  if (response.requestFailed) {
    return rejectWithValue('Failed to initialize pre-season approval');
  }

  return response.value;
});
