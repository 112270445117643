import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthService } from '../../../utils/auth/AuthService';

export const logoutThunk = createAsyncThunk('auth/logout', async () => {
  AuthService.mostRecentDealer = { accountNum: '', name: '' };

  // remove all local storage values prefixed with dp
  // get all the keys
  const dpKeys = Object.keys(localStorage).filter(x => x.startsWith('dp'));
  dpKeys.forEach(x => localStorage.removeItem(x));

  AuthService.logout();
});
