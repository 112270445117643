import { createAsyncThunk } from '@reduxjs/toolkit';
import { INewsFeedPost } from '..';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';

export const fetchNewsFeedPostsThunk = createAsyncThunk('fetchNewsFeedPosts', async (_, { rejectWithValue }) => {
  const response = await executeApiRequest<INewsFeedPost[]>(() => httpService.get('/NewsFeed?minimum=true'));

  if (response.requestFailed) {
    return rejectWithValue('Failed to fetch news feed posts');
  }

  return response.value ?? [];
});
