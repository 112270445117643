import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthProviders } from '../../../utils/auth/authInterfaces';
import { AuthService } from '../../../utils/auth/AuthService';
import { initAppThunk } from '../../core';

export type LoginThunkRejection = { serverError?: boolean; rejectedEmail?: string };

export const loginThunk = createAsyncThunk<undefined, AuthProviders, { rejectValue: LoginThunkRejection }>(
  'auth/login',
  async (provider: AuthProviders, { rejectWithValue, dispatch }) => {
    try {
      const authCode =
        provider === 'google'
          ? await AuthService.fetchGoogleAuthorizationCode(provider)
          : await AuthService.fetchAuthorizationCode(provider);
      console.log('AuthCode', authCode);
      const accessTokenResponse =
        provider === 'google'
          ? await AuthService.fetchGoogleAccessToken(authCode)
          : await AuthService.fetchAccessToken(authCode);
      console.log('accessTokenResponse', accessTokenResponse);
      if (accessTokenResponse.isAuthed) {
        dispatch(initAppThunk(true));
      } else {
        return rejectWithValue({ rejectedEmail: accessTokenResponse.rejectedEmail });
      }
    } catch (error) {
      console.error('loginError', error);
      return rejectWithValue({ serverError: true });
    }
  }
);
