import * as React from 'react';
import styled from 'styled-components';
import { ColorLine } from '../components/ColorLine';
import { CopyrightText } from '../components/CopyrightText';
import { TermsAndConditionsLink } from '../components/TermsAndConditions/TermsAndConditionsLink';
import { useAppSelector } from '../hooks';
import { LoginButtonPanel } from './LoginButtonPanel';
import { LogoutAlert } from './LogoutAlert';

interface ILoginPageProps {}

const Container = styled.div<{ showAlert: boolean }>`
  height: ${props => (props.showAlert ? 'calc(100vh - 74px)' : 'calc(100vh - 6px)')};
  overflow: hidden;
`;

const FlexColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
`;

let LoginPage: React.ComponentType<ILoginPageProps> = () => {
  const showLogoutAlert = useAppSelector(state => state.auth.showLogoutAlert);

  return (
    <Container showAlert={showLogoutAlert}>
      <ColorLine />
      <LogoutAlert showLogoutAlert={showLogoutAlert} />
      <FlexColumn id="flex">
        <div>
          <LoginButtonPanel />
          <div className="text-center">
            <CopyrightText />
            <TermsAndConditionsLink />
          </div>
        </div>
      </FlexColumn>
    </Container>
  );
};

LoginPage = React.memo(LoginPage);
export { LoginPage };
