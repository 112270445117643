import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorLogger, httpService } from '../../..';
import { IActiveCart } from '../../carts';
import { ICashDiscount } from '../../cashDiscounts';
import { fetchLookupsThunk } from '../../lookups';
import { IMe, ISettings } from '../coreInterfaces';
import { coreActions } from '../coreSlice';

async function fetchData<T>(url: string): Promise<T> {
  const response = await httpService.get(url);

  return await response.json();
}

export const initAppThunk = createAsyncThunk(
  'core/initApp',
  async (showFullLoading: boolean, { rejectWithValue, dispatch }) => {
    try {
      const [me, settings, activeCart, cashDiscounts] = await Promise.all([
        fetchData<IMe>('/Me'),
        fetchData<ISettings>('/Settings'),
        fetchData<IActiveCart>('/Cart/ActiveCart'),
        fetchData<ICashDiscount[]>('/CashDiscounts?fiscalYear='),
        import(/* webpackChunkName: 'MainPage' */ '../../../layout/MainPage'),
        dispatch(fetchLookupsThunk(['brands']))
      ]);

      if (me) {
        errorLogger.setUser(me.email, me.name);
      }

      if (activeCart.notificationString) {
        dispatch(coreActions.toggleActiveCartMessageDialog(activeCart.notificationString));
      }

      return { activeCart, cashDiscounts, me, settings };
    } catch (error) {
      return rejectWithValue('Failed to init app');
    }
  }
);
