import { createAsyncThunk } from '@reduxjs/toolkit';
import { IItemInstruction } from '..';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';

export const fetchInstructionsThunk = createAsyncThunk(
  'catalog/fetchInstructions',
  async (itemId: string, { rejectWithValue }) => {
    const response = await executeApiRequest<IItemInstruction[]>(() =>
      httpService.get(`/Products/${itemId}/Instructions`)
    );

    if (response.requestFailed) {
      return rejectWithValue(`Failed to fetch instructions for item ${itemId}`);
    }

    return response.value ?? [];
  }
);
