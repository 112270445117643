import { createSlice, EntityAdapter, EntityState } from '@reduxjs/toolkit';
import { uniq } from 'lodash-es';
import { lookupEntityAdapters } from './lookupsEntityAdapters';
import * as i from './lookupsInterfaces';
import { fetchLookupsThunk } from './thunks';

interface IEntities {
  brands: EntityState<i.IBrand>;
  dpNewsFeedTags: EntityState<i.IDpNewsFeedTag>;
  dpProductFamilies: EntityState<i.IDpProductFamily>;
  states: EntityState<i.IState>;
  warehouses: EntityState<i.IWarehouse>;
}

interface ILookupsState extends IEntities {
  loaded: Array<keyof i.ILookupApiResponse>;
  loading: Array<keyof i.ILookupApiResponse>;
}

const initialState: ILookupsState = {
  brands: lookupEntityAdapters.brands.getInitialState(),
  dpNewsFeedTags: lookupEntityAdapters.dpNewsFeedTags.getInitialState(),
  dpProductFamilies: lookupEntityAdapters.dpProductFamilies.getInitialState(),
  loaded: [],
  loading: [],
  states: lookupEntityAdapters.states.getInitialState(),
  warehouses: lookupEntityAdapters.warehouses.getInitialState()
};

const lookupsSlice = createSlice({
  initialState,
  name: 'lookups',
  reducers: {},
  // eslint-disable-next-line sort-keys
  extraReducers: builder => {
    builder
      .addCase(fetchLookupsThunk.pending, (state, action) => {
        state.loading = uniq([...state.loading, ...action.meta.arg]);
      })
      .addCase(fetchLookupsThunk.fulfilled, (state, action) => {
        state.loading = state.loading.filter(x => !action.meta.arg.includes(x));
        state.loaded = uniq([...state.loaded, ...action.meta.arg]);

        Object.keys(action.payload).forEach(key => {
          // cast the string key to an IEntity
          const typedKey = key as keyof IEntities;
          // get the entity adapter, type it as unknown because we don't know what it is
          const adapter = lookupEntityAdapters[typedKey] as EntityAdapter<unknown>;

          // this is disabled because we are iterating over the object so we know the value exists
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          adapter.upsertMany(state[typedKey], action.payload[typedKey]!);
        });
      })
      .addCase(fetchLookupsThunk.rejected, (state, action) => {
        state.loading = state.loading.filter(x => !action.meta.arg.includes(x));
      });
  }
});

export const { reducer: lookupsReducer } = lookupsSlice;
