import { createEntityAdapter } from '@reduxjs/toolkit';
import { IColor, IItem } from '.';
import { IProductPrice } from './catalogInterfaces';

export const catalogEntityAdapters = {
  colors: createEntityAdapter<Omit<IColor, 'itemId'>>({ selectId: x => x.inventColorId }),
  items: createEntityAdapter<IItem>({
    selectId: x => x.itemId,
    sortComparer: (a, b) => a.itemId.localeCompare(b.itemId)
  }),
  prices: createEntityAdapter<{ itemId: string; prices: IProductPrice[] }>({ selectId: x => x.itemId })
};
