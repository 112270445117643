import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { IItem } from '../catalogInterfaces';

export const fetchItemThunk = createAsyncThunk(
  'catalog/fetchItem',
  async (
    { itemId, includePrices = false }: { itemId: string | string[]; includePrices?: boolean },
    { rejectWithValue }
  ) => {
    const itemIdArr = Array.isArray(itemId) ? itemId : [itemId];

    const result = await executeApiRequest<IItem[]>(() =>
      httpService.get(`/Products?includePrices=${includePrices}${httpService.createQueryString('itemIds', itemIdArr)}`)
    );

    if (result.requestFailed) {
      rejectWithValue(`Failed to fetch items ${itemIdArr.join(', ')}`);
    }

    return result.value ? result.value : [];
  }
);
