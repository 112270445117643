import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { IProfile, IProfileApiResponse } from '../profileInterfaces';

export const updateProfileThunk = createAsyncThunk('profile/update', async (profile: IProfile, { rejectWithValue }) => {
  const result = await executeApiRequest<IProfileApiResponse>(() => httpService.put('/Dealers/Profile', profile));

  if (result.requestFailed) {
    toast('There was a problem updating the profile.  Please retry', { type: 'error' });

    return rejectWithValue('Failed to update dealer profile');
  }
  toast('Updated dealer profile', { type: 'success' });

  return result.value;
});
