import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { coreActions } from '../../core';

export const deleteConfigurationThunk = createAsyncThunk(
  'configurator/delete',
  async (configNumber: string, { rejectWithValue, dispatch }) => {
    dispatch(coreActions.toggleWorkingOnItDialog());
    const response = await executeApiRequest(() => httpService.delete(`/Configurations/${configNumber}`), false);
    dispatch(coreActions.toggleWorkingOnItDialog());

    if (response.requestFailed) {
      return rejectWithValue(`Failed to delete ${configNumber}`);
    }

    return configNumber;
  }
);
