import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import { lookupEntityAdapters } from './lookupsEntityAdapters';
import { OnDemandLookupTypes } from './lookupsInterfaces';

export const brandSelectors = lookupEntityAdapters.brands.getSelectors<RootState>(state => state.lookups.brands);
export const stateSelectors = lookupEntityAdapters.states.getSelectors<RootState>(state => state.lookups.states);
export const warehouseSelectors = lookupEntityAdapters.warehouses.getSelectors<RootState>(
  state => state.lookups.warehouses
);
export const dpNewsFeedTagSelectors = lookupEntityAdapters.dpNewsFeedTags.getSelectors<RootState>(
  state => state.lookups.dpNewsFeedTags
);
export const dpProductFamilySelectors = lookupEntityAdapters.dpProductFamilies.getSelectors<RootState>(
  state => state.lookups.dpProductFamilies
);

export const getNonLoadedOrLoadingLookups = createSelector(
  [
    (state: RootState) => [...state.lookups.loaded, ...state.lookups.loading],
    (_: unknown, types: OnDemandLookupTypes[]) => types
  ],
  (loaded, types) => types.filter(x => !loaded.includes(x))
);

export const getIsLookupLoading = createSelector(
  [
    (state: RootState) => state.lookups.loading,
    (_: RootState, type: OnDemandLookupTypes | OnDemandLookupTypes[]) => type
  ],
  (loadingArr, type) => (Array.isArray(type) ? type.every(x => loadingArr.includes(x)) : loadingArr.includes(type))
);

export const getIsLookupLoaded = createSelector(
  [
    (state: RootState) => state.lookups.loaded,
    (_: RootState, type: OnDemandLookupTypes | OnDemandLookupTypes[]) => type
  ],
  (loadedArr, type) => (Array.isArray(type) ? type.every(x => loadedArr.includes(x)) : loadedArr.includes(type))
);

export const getBrandName = createSelector(
  [brandSelectors.selectAll, (_: RootState, brandAbbreviation: string) => brandAbbreviation],
  (brands, abbreviateion): string => {
    const brand = brands.find(x => x.abbreviation === abbreviateion);
    return brand?.brand ?? abbreviateion;
  }
);
