import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { ISubPartItem } from '../catalogInterfaces';

export const fetchSubPartsThunk = createAsyncThunk(
  'catalog/fetchSubParts',
  async (itemId: string, { rejectWithValue }) => {
    const results = await executeApiRequest<ISubPartItem[]>(() => httpService.get(`/Products/${itemId}/SubParts`));

    if (results.requestFailed) {
      return rejectWithValue(`Failed to fetch sub parts for item ${itemId}`);
    }

    return results.value ?? [];
  }
);
