import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPreseasonSummaryApiResponse } from '..';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';

export const initPreSeasonThunk = createAsyncThunk(
  'preSeason/init',
  async (brand: string | undefined = '', { rejectWithValue }) => {
    const response = await executeApiRequest<IPreseasonSummaryApiResponse>(() =>
      httpService.get(`/Preseason/Summary?brand=${brand}`)
    );

    if (response.requestFailed) {
      return rejectWithValue(`Failed to fetch pre-season summary.`);
    }

    return response.value;
  }
);
