import jwtDecode from 'jwt-decode';
import { oauthConfigs } from './authConfigs';
import {
  AuthProviders,
  IAccessTokenResponse,
  IAuthorizationCodeResponse,
  IDpToken,
  IRefreshTokenResponse
} from './authInterfaces';
import { EntraService } from './EntraService';

const storageTypes = {
  accessTokenDecoded: 'dp.auth.accessToken.decoded',
  accessTokenKey: 'dp.auth.accessToken',
  mostRecentDealerKey: 'dp.auth.mostRecentDealer',
  refreshTokenKey: 'dp.auth.refreshToken'
} as const;

type storageTypeKeys = keyof typeof storageTypes;

const storageLocations: Record<storageTypeKeys, 'sessionStorage' | 'localStorage'> = {
  accessTokenDecoded: 'sessionStorage',
  accessTokenKey: 'sessionStorage',
  mostRecentDealerKey: 'localStorage',
  refreshTokenKey: 'localStorage'
};

export class AuthService {
  private static readonly authUrl = `${process.env.REACT_APP_AUTH_URL}auth`;
  static get mostRecentDealer(): { accountNum: string; name: string } {
    const dealerString = this.getStorageValue('mostRecentDealerKey');
    return dealerString ? JSON.parse(dealerString) : { accountNum: '', name: '' };
  }

  static set mostRecentDealer(dealer: { accountNum: string; name: string }) {
    this.setStorageValue('mostRecentDealerKey', JSON.stringify(dealer));
  }

  static get accessToken(): string {
    const fromStorage = this.getStorageValue('accessTokenKey');
    return fromStorage ?? '';
  }

  private static clearStorage(full: boolean = false): void {
    try {
      this.removeStorageValue('accessTokenDecoded');
      this.removeStorageValue('accessTokenKey');
      this.removeStorageValue('refreshTokenKey');

      if (full) {
        this.removeStorageValue('mostRecentDealerKey');
      }
    } catch {
      //
    }
  }

  private static setAuthTokensInStorage(tokens: IAccessTokenResponse): void {
    this.clearStorage();

    this.setStorageValue('refreshTokenKey', tokens.refreshToken);
    if (tokens.accessToken) {
      this.setStorageValue('accessTokenKey', tokens.accessToken);
    }
    if (tokens.decodedToken) {
      this.setStorageValue('accessTokenDecoded', JSON.stringify(tokens.decodedToken));
    }
  }

  public static getAuthTokens(): IAccessTokenResponse | undefined {
    const accessToken = this.getStorageValue('accessTokenKey') ?? '';
    const refreshToken = this.getStorageValue('refreshTokenKey');
    const decodedString = this.getStorageValue('accessTokenDecoded');

    if (refreshToken) {
      const response: IAccessTokenResponse = { accessToken, refreshToken };

      if (decodedString) {
        response.decodedToken = JSON.parse(decodedString);
      }

      return response;
    }
  }

  private static parseQueryString(queryString: string): Record<string, string> {
    queryString = queryString.substring(1).replace(/\$/, '');
    const obj: Record<string, string> = { code: '' };
    let key: string;
    let value: string[];
    (queryString || '').split('&').forEach(keyValue => {
      if (keyValue) {
        value = keyValue.split('=');
        key = decodeURIComponent(value[0]);
        obj[key] = decodeURIComponent(value[1]);
      }
    });

    return obj;
  }

  private static getStorageValue(type: storageTypeKeys): string | null {
    return window[storageLocations[type]].getItem(storageTypes[type]);
  }

  private static removeStorageValue(type: storageTypeKeys): void {
    window[storageLocations[type]].removeItem(storageTypes[type]);
  }

  private static setStorageValue(type: storageTypeKeys, value: string): void {
    window[storageLocations[type]].setItem(storageTypes[type], value);
  }

  private static getQueryResponse(location: Location): { code: string; error?: string } {
    const search = this.parseQueryString(location.search);
    const hash = this.parseQueryString(location.hash);

    const response: { code: string; error?: string } = {
      code: ''
    };

    if (search.code) {
      response.code = search.code;
    }

    if (search.error || hash.error) {
      const error = search.error ? search.error : hash.error;
      response.error = error;
    }

    return response;
  }

  public static fetchAuthorizationCode(provider: AuthProviders): Promise<IAuthorizationCodeResponse> {
    const { clientId, redirectUri } = oauthConfigs[provider];
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const idToken = await EntraService.openLoginPopup();
      const accessToken = await EntraService.getAccessTokenSilent();

      if (idToken === 'error') {
        reject(new Error('Error Signing In'));
      } else {
        resolve({ accessToken, clientId: clientId ?? '', code: idToken, provider, redirectUri });
      }
    });
  }

  public static fetchGoogleAuthorizationCode(provider: AuthProviders): Promise<IAuthorizationCodeResponse> {
    const {
      authorizationEndpoint,
      clientId,
      redirectUri,
      resource,
      popupOptions: { height, width },
      scope
    } = oauthConfigs[provider];

    return new Promise((resolve, reject) => {
      const top = window.screenY + (window.outerHeight - height) / 2.5;
      const left = window.screenX + (window.outerWidth - width) / 2;

      const oauthUrl = `${authorizationEndpoint}?\
response_type=code\
${clientId ? `&client_id=${clientId}` : ''}\
&redirect_uri=${redirectUri}\
${resource ? `&resource=${resource}` : ''}\
${scope ? `&scope=${scope.join(' ')}` : ''}`;

      const authPopup = window.open(oauthUrl, '_blank', `height=${height},width=${width},top=${top},left=${left}`);

      // check if popup is blockd
      if (!authPopup) {
        reject(new Error('Failed to open popup'));
      } else {
        authPopup.focus();

        const interval = setInterval(() => {
          if (!authPopup || authPopup.closed || authPopup.closed === undefined) {
            clearInterval(interval);
            reject(new Error('Auth window was closed by user'));
          }

          try {
            const popupOrigin = authPopup.location.origin;

            if (redirectUri === popupOrigin || redirectUri === `${popupOrigin}/`) {
              const response = this.getQueryResponse(authPopup.location);

              if (response.error) {
                reject(new Error(response.error));
              } else {
                resolve({ clientId: clientId ?? '', code: response.code, provider, redirectUri });
              }

              authPopup.close();
              clearInterval(interval);
            } else {
              console.log('popupOrigin', popupOrigin);
              console.log('redirectUri', redirectUri);
            }
          } catch (error) {
            // this catches same-origin security exceptions
            // we don't do anything with them, just silently swallow
          }
        }, 500);
      }
    });
  }

  public static async fetchGoogleAccessToken(
    code: IAuthorizationCodeResponse
  ): Promise<{ isAuthed: true; tokens: IAccessTokenResponse } | { isAuthed: false; rejectedEmail: string }> {
    const url = `${this.authUrl}/${code.provider}`;
    const response = await fetch(url, {
      body: JSON.stringify(code),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST'
    });
    if (response.ok) {
      const { refreshToken, token } = (await response.json()) as { token: string; refreshToken: string };
      const decoded = jwtDecode<IDpToken>(token);

      const tokenResponse: IAccessTokenResponse = {
        accessToken: token,
        decodedToken: decoded,
        refreshToken
      };

      this.setAuthTokensInStorage(tokenResponse);

      return { isAuthed: true, tokens: tokenResponse };
    }

    const { rejectedEmail } = (await response.json()) as { rejectedEmail: string };

    return { isAuthed: false, rejectedEmail };
  }

  public static async fetchAccessToken(
    code: IAuthorizationCodeResponse
  ): Promise<{ isAuthed: true; tokens: IAccessTokenResponse } | { isAuthed: false; rejectedEmail: string }> {
    const accessToken = await EntraService.getAccessTokenSilent();
    code.code = accessToken;
    const url = `${this.authUrl}/${code.provider}`;
    const response = await fetch(url, {
      body: JSON.stringify(code),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST'
    });

    if (response.ok) {
      const { refreshToken, token } = (await response.json()) as { token: string; refreshToken: string };
      const decoded = jwtDecode<IDpToken>(token);

      const tokenResponse: IAccessTokenResponse = {
        accessToken: token,
        decodedToken: decoded,
        refreshToken
      };

      this.setAuthTokensInStorage(tokenResponse);

      return { isAuthed: true, tokens: tokenResponse };
    }

    const { rejectedEmail } = (await response.json()) as { rejectedEmail: string };

    return { isAuthed: false, rejectedEmail };
  }

  public static async refreshAccessToken(
    refreshToken: string,
    accountNum?: string
  ): Promise<IAccessTokenResponse | undefined> {
    let url = `${process.env.REACT_APP_AUTH_URL}dp/oauth2/token`;
    if (accountNum) {
      url += `?accountNum=${accountNum}`;
    }

    const urlEncoded = new URLSearchParams();
    urlEncoded.append('client_id', 'dealer_portal');
    urlEncoded.append('grant_type', 'refresh_token');
    urlEncoded.append('refresh_token', refreshToken);

    const response = await fetch(url, {
      body: urlEncoded,
      method: 'POST'
    });

    if (!response.ok) {
      throw new Error('Failed to update refresh token');
    }

    const tokens = (await response.json()) as IRefreshTokenResponse;
    const responseTokens: IAccessTokenResponse = {
      accessToken: tokens.access_token,
      decodedToken: jwtDecode<IDpToken>(tokens.access_token),
      refreshToken: tokens.refresh_token
    };

    this.setAuthTokensInStorage(responseTokens);

    return responseTokens;
  }

  public static isAccessTokenExpired = (token: IDpToken): boolean => new Date(token.exp * 1000) < new Date();

  public static async fetchCurrentAccessToken(accountNum?: string): Promise<IAccessTokenResponse | undefined> {
    let tokens = this.getAuthTokens();
    if (tokens) {
      if (!tokens.decodedToken || this.isAccessTokenExpired(tokens.decodedToken)) {
        tokens = await this.refreshAccessToken(tokens.refreshToken, accountNum);
      }
    }

    return tokens;
  }

  public static async logout(): Promise<void> {
    const currenTokens = this.getAuthTokens();
    const url = `${this.authUrl}/logout`;

    this.clearStorage(true);

    if (currenTokens?.refreshToken) {
      await fetch(url, {
        body: JSON.stringify({ refreshToken: currenTokens.refreshToken }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST'
      });
    }
  }
}
