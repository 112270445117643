import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { IProductPrice } from '../catalogInterfaces';

export const fetchPricesThunk = createAsyncThunk('catalog/fetchPrices', async (itemId: string, { rejectWithValue }) => {
  const response = await executeApiRequest<IProductPrice[]>(() => httpService.get(`/Products/${itemId}/Prices`));

  if (response.requestFailed) {
    return rejectWithValue(`Failed to fetch prices for item ${itemId}`);
  }

  return response.value ?? [];
});
