import { createSelector } from '@reduxjs/toolkit';
import { isWithinInterval } from 'date-fns';
import { RootState } from '..';
import { cashDiscountsEntityAdapter } from './cashDiscountsSlice';

export const cashDiscountsSelectors = cashDiscountsEntityAdapter.getSelectors<RootState>(state => state.cashDiscounts);

export const getCashDiscount = createSelector(
  [
    (state: RootState) => Object.values(state.cashDiscounts.entities).flatMap(x => x?.discounts ?? []),
    (_: RootState, date: Date | string | undefined) => date
  ],
  (discounts, date) =>
    date
      ? discounts.find(x =>
          isWithinInterval(new Date(date), { end: new Date(x.endDate), start: new Date(x.startDate) })
        )
      : undefined
);
