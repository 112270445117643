import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { IAccessTokenResponse } from '../../../utils/auth/authInterfaces';
import { AuthService } from '../../../utils/auth/AuthService';
import { initAppThunk } from '../../core';

type RejectionTypes = 'failedToRefresh' | 'noAuthToken' | '';

export const checkAuthTokensThunk = createAsyncThunk<
  IAccessTokenResponse | undefined,
  void,
  { rejectValue: { reason: RejectionTypes } }
>('auth/checkAuthTokens', async (_, { dispatch, rejectWithValue, getState }) => {
  const state = getState() as RootState;
  let tokens = AuthService.getAuthTokens();
  const rejectionReason: { reason: RejectionTypes } = { reason: '' };

  if (tokens) {
    if (!tokens.decodedToken || AuthService.isAccessTokenExpired(tokens.decodedToken)) {
      // there is no token, need a new one

      try {
        tokens = await AuthService.refreshAccessToken(
          tokens.refreshToken,
          state.auth.employeeSelectedDealer.accountNum
        );
      } catch {
        rejectionReason.reason = 'failedToRefresh';
        return rejectWithValue(rejectionReason);
      }
    }
    // we need to check the most recent dealer against the decoded token, if they don't match, fetch a new token
    else if (tokens.decodedToken && tokens.decodedToken.accountNum !== state.auth.employeeSelectedDealer.accountNum) {
      // the accountNums don't match, we need to get a new token
      try {
        tokens = await AuthService.refreshAccessToken(
          tokens.refreshToken,
          state.auth.employeeSelectedDealer.accountNum
        );
      } catch {
        rejectionReason.reason = 'failedToRefresh';
        return rejectWithValue(rejectionReason);
      }
    }

    dispatch(initAppThunk(true));
    return tokens;
  }

  rejectionReason.reason = 'noAuthToken';
  return rejectWithValue(rejectionReason);
});
