import { combineReducers } from '@reduxjs/toolkit';
import { addressesReducer } from './addresses';
import { authReducer } from './auth';
import { cartsReducer } from './carts';
import { cashDiscountsReducer } from './cashDiscounts';
import { catalogReducer } from './catalog';
import { configuratorReducer } from './configurator';
import { coreReducer } from './core';
import { inventoryReducer } from './inventory';
import { lookupsReducer } from './lookups';
import { newsFeedReducer } from './newsFeed';
import { ordersReducer } from './orders';
import { preSeasonReducer } from './preSeason';
import { profileReducer } from './profile';

export const rootReducer = combineReducers({
  addresses: addressesReducer,
  auth: authReducer,
  carts: cartsReducer,
  cashDiscounts: cashDiscountsReducer,
  catalog: catalogReducer,
  configurator: configuratorReducer,
  core: coreReducer,
  inventory: inventoryReducer,
  lookups: lookupsReducer,
  newsFeed: newsFeedReducer,
  orders: ordersReducer,
  preSeason: preSeasonReducer,
  profile: profileReducer
});

export type RootState = ReturnType<typeof rootReducer>;
