import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { IProfileContact } from '../profileInterfaces';

export const updateContactThunk = createAsyncThunk(
  'profile/updateContact',
  async (contact: IProfileContact, { rejectWithValue }) => {
    const result = await executeApiRequest<IProfileContact>(() => httpService.put('/Dealers/Contacts', contact));

    if (result.requestFailed) {
      toast('Failed to update contact.', { type: 'error' });
      return rejectWithValue('Failed to update contact');
    }

    toast('Contact updated successfully', { type: 'success' });
    return result.value;
  }
);
