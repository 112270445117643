import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { getFiscalYear } from '../../../utils/fiscalYearUtils';
import { ICashDiscount } from '../cashDiscountsInterfaces';

export const _fetchCashDiscountsThunk = createAsyncThunk(
  'cashDiscounts/fetch',
  async (fiscalYear: string, { rejectWithValue }) => {
    const response = await executeApiRequest<ICashDiscount[]>(() =>
      httpService.get(`CashDiscounts?fiscalYear=${fiscalYear}`)
    );

    if (response.requestFailed) {
      return rejectWithValue('Failed to fetch cash discounts');
    }

    return { cashDiscounts: response.value ?? [] };
  }
);

export const fetchCashDiscountsThunk = (fiscalYear: string = getFiscalYear()) => _fetchCashDiscountsThunk(fiscalYear);
