import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import { IInventory } from './inventoryInterfaces';

export const getInventory = createSelector(
  [(state: RootState) => state.inventory.itemHash, (_: RootState, itemId: string) => itemId],
  (hash, itemId): IInventory[] => Object.values(hash).filter(x => x.itemId === itemId)
);

export const getIsInventoryLoading = createSelector(
  [(state: RootState) => state.inventory.loading, (_: RootState, itemId: string) => itemId],
  (arr, itemId): boolean => arr.includes(itemId)
);
