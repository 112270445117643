import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { IOrder } from '../ordersInterfaces';

export const fetchOrderThunk = createAsyncThunk('orders/fetchOrder', async (salesId: string, { rejectWithValue }) => {
  const response = await executeApiRequest<IOrder>(() => httpService.get(`/Orders/${salesId}`));

  if (response.requestFailed) {
    return rejectWithValue(`Failed to fetch sales order ${salesId}`);
  }

  return response.value;
});
