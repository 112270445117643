export function brandAbbreviationToString(brandAbbreviation: string): string {
  switch (brandAbbreviation) {
    case 'PDP':
      return 'PolyDock Products';
    case 'SLI':
      return 'Shoreline Industries';
    case 'OEM':
      return 'OEM Industries';
    case 'RHP':
      return 'Rhino Marine Systems';
    case 'SMR':
      return 'ShoreMaster';
    case 'HYH':
      return 'HydroHoist';
    default:
      // TODO log to raygun
      return 'Unknown';
  }
}

export function brandIdToString(brandId: number): string {
  switch (brandId) {
    case 5:
      return 'PolyDock Products';
    case 2:
      return 'Shoreline Industries';
    case 3:
      return 'OEM Industries';
    case 4:
      return 'Rhino Marine Systems';
    case 1:
      return 'ShoreMaster';
    case 6:
      return 'HydroHoist';
    default:
      // TODO log to raygun
      return 'Unknown';
  }
}

export function brandAbbreviationToCssTextClass(brandAbbreviation: string): string {
  switch (brandAbbreviation) {
    case 'PDP':
      return 'text-poly';
    case 'SLI':
      return 'text-shoreline';
    case 'OEM':
      return '';
    case 'RHP':
      return 'text-rhino';
    case 'SMR':
      return 'text-shoremaster';
    case 'HYH':
      return 'text-hydrohoist';
    default:
      // TODO log to raygun
      return '';
  }
}

export function brandAbbreviationToPanelHeader(brandAbbreviation: string): string {
  switch (brandAbbreviation) {
    case 'PDP':
      return 'hpoly';
    case 'SLI':
      return 'hshoreline';
    case 'OEM':
      return '';
    case 'RHP':
      return 'hrhino';
    case 'SMR':
      return 'hshoremaster';
    case 'HYH':
      return 'hhydrohoist';
    default:
      // TODO log to raygun
      return '';
  }
}

export function brandIdToCssTextClass(brandId: number): string {
  switch (brandId) {
    case 5:
      return 'text-poly';
    case 2:
      return 'text-shoreline';
    case 3:
      return '';
    case 4:
      return 'text-rhino';
    case 1:
      return 'text-shoremaster';
    case 6:
      return 'text-hydrohoist';
    default:
      // TODO log to raygun
      return '';
  }
}

export function brandAbbreviationToCssBackgroundClass(brandAbbreviation: string): string {
  switch (brandAbbreviation) {
    case 'PDP':
      return 'background-poly';
    case 'SLI':
      return 'background-shoreline';
    case 'OEM':
      return '';
    case 'RHP':
      return 'background-rhino';
    case 'SMR':
      return 'background-shoremaster';
    case 'HYH':
      return 'background-hydrohoist';
    default:
      // TODO log to raygun
      return '';
  }
}
