import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { ICatalogSectionResult } from '../preSeasonInterfaces';

export const fetchCatalogSectionThunk = createAsyncThunk(
  'preSeason/fetchCatalogSection',
  async (sectionId: number, { rejectWithValue }) => {
    const result = await executeApiRequest<ICatalogSectionResult>(() =>
      httpService.get(`/Preseason/CatalogSections?sectionId=${sectionId}`)
    );
    if (result.requestFailed) {
      return rejectWithValue(`Failed to fetch pre-season catalog section ${sectionId}`);
    }

    return result.value;
  }
);
