import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest, IEditObject } from '../../../utils/asyncUtils';
import { coreActions } from '../../core';

export const editPreSeasonOrderThunk = createAsyncThunk(
  'preSeason/editOrder',
  async ({ brand, year }: { year: string; brand: string }, { rejectWithValue, dispatch }) => {
    dispatch(coreActions.toggleWorkingOnItDialog());

    const response = await executeApiRequest<IEditObject<string>>(() =>
      httpService.post(`/Preseason/EditOrder?brand=${brand}&year=${year}`)
    );
    dispatch(coreActions.toggleWorkingOnItDialog());

    if (response.requestFailed) {
      return rejectWithValue(`Failed to enable editing for pre-season order ${brand}, ${year}`);
    }

    return response.value;
  }
);
