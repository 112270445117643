import { createSlice } from '@reduxjs/toolkit';
import { AuthService } from '../../utils/auth/AuthService';
import {
  checkAuthTokensThunk,
  fetchCurrentAccessTokenThunk,
  loginThunk,
  logoutThunk,
  switchDealerThunk
} from './thunks';

interface IAuthState {
  employeeSelectedDealer: { accountNum: string; name: string };
  isDealerSwitching: boolean;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  loginError: boolean;
  showLogoutAlert: boolean;
}

const getInitialState = (): IAuthState => ({
  employeeSelectedDealer: AuthService.mostRecentDealer,
  isDealerSwitching: false,
  isLoggedIn: false,
  isLoggingIn: false,
  loginError: false,
  showLogoutAlert: false
});

const authSlice = createSlice({
  initialState: getInitialState(),
  name: 'auth',
  reducers: {},
  // eslint-disable-next-line sort-keys
  extraReducers: builder => {
    builder
      .addCase(checkAuthTokensThunk.pending, state => {
        state.isLoggingIn = true;
        state.loginError = false;
      })
      .addCase(checkAuthTokensThunk.fulfilled, state => {
        state.isLoggedIn = true;
        state.isLoggingIn = false;
      })
      .addCase(checkAuthTokensThunk.rejected, (state, action) => {
        state.isLoggingIn = false;
        state.isLoggedIn = false;

        if (action.payload?.reason === 'failedToRefresh') {
          state.loginError = true;
        }
      })
      .addCase(fetchCurrentAccessTokenThunk.fulfilled, (state, action) => {
        if (
          action.payload.decodedToken?.accountNum !== state.employeeSelectedDealer.accountNum &&
          !state.isDealerSwitching
        ) {
          // if for some reason the returned access token has a different account number than is displayed
          // to the user
          state.employeeSelectedDealer = {
            accountNum: action.payload.decodedToken?.accountNum ?? '',
            name: ''
          };
        }
      })
      .addCase(fetchCurrentAccessTokenThunk.rejected, state => {
        state.isLoggedIn = false;
        state.loginError = true;
        state.isLoggingIn = false;
        state.employeeSelectedDealer = { accountNum: '', name: '' };
      })
      .addCase(loginThunk.pending, state => {
        state.isLoggingIn = true;
        state.loginError = false;
      })
      .addCase(loginThunk.fulfilled, state => {
        state.isLoggedIn = true;
        state.isLoggingIn = false;
      })
      .addCase(loginThunk.rejected, state => {
        state.isLoggingIn = false;
        state.isLoggedIn = false;
        state.loginError = true;
        state.employeeSelectedDealer = { accountNum: '', name: '' };
      })
      .addCase(logoutThunk.fulfilled, () => ({ ...getInitialState(), showLogoutAlert: true }))
      .addCase(switchDealerThunk.pending, state => {
        state.isDealerSwitching = true;
      })
      .addCase(switchDealerThunk.fulfilled, (state, action) => {
        state.isDealerSwitching = false;
        if (action.payload) {
          state.employeeSelectedDealer = action.payload;
        }
      })
      .addCase(switchDealerThunk.rejected, state => {
        state.isDealerSwitching = false;
        state.isLoggedIn = false;
        state.isLoggingIn = false;
        state.loginError = true;
        state.employeeSelectedDealer = { accountNum: '', name: '' };
      });
  }
});

export const { actions: authActions, reducer: authReducer } = authSlice;
