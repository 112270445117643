import { createAsyncThunk } from '@reduxjs/toolkit';
import { ISubmitSummaryApiResponse } from '..';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';

export const initSubmitPreSeasonThunk = createAsyncThunk('preSeason/initSubmit', async (_, { rejectWithValue }) => {
  const result = await executeApiRequest<ISubmitSummaryApiResponse>(() => httpService.get(`/Preseason/InitSubmit`));

  if (result.requestFailed) {
    return rejectWithValue('Failed to initialize pre-season order page');
  }

  return result.value;
});

// function* initSubmitPreseasonSaga(action: InitSubmitPreseason): any {
//   const summary: ISagaResponse<ISubmitSummaryApiResponse> = yield call(initSubmitPreseasonAsync);
//   if (!summary.error) {
//     yield put(preseasonActionCreators.initSubmitPreseasonFulfilled(summary.value));

//     if (summary.value.paymentTerms) {
//       yield put(paymentTermsActionCreators.set(summary.value.paymentTerms));
//     }
//   } else {
//     console.log(summary.error);
//     yield put(preseasonActionCreators.initSubmitPreseasonError(summary.error));
//   }
// }

// async function initSubmitPreseasonAsync(): Promise<ISagaResponse<ISubmitSummaryApiResponse>> {
//   let error: Error;
//   let value: ISubmitSummaryApiResponse;

//   try {
//     const response = await httpService.get(`/Preseason/InitSubmit`);

//     value = await response.json();
//   } catch (e) {
//     error = e;
//   }

//   return { error, value };
// }

// export function* watchInitSubmitPreseason(): any {
//   yield takeLatest(PreseasonActionKeys.INIT_SUBMIT_PRESEASON, initSubmitPreseasonSaga);
// }
