import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ColorLine } from '../components/ColorLine';
import { LoginErrorServerErrorBody } from './LoginErrorServerErrorBody';
import { LoginErrorUnauthorizedBody } from './LoginErrorUnauthorizedBody';

interface ILoginErrorDialogProps {
  variant: 'unauthorized' | 'serverError';
  email?: string;
  isOpen: boolean;
  onClose: () => void;
}

let LoginErrorDialog: React.ComponentType<ILoginErrorDialogProps> = ({ email, isOpen, onClose, variant }) => {
  return (
    <Modal show={isOpen} onHide={onClose}>
      <ColorLine />
      <Modal.Body>
        <div className="text-center">
          {variant === 'unauthorized' && <LoginErrorUnauthorizedBody email={email} />}
          {variant === 'serverError' && <LoginErrorServerErrorBody />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="primary" onClick={onClose}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

LoginErrorDialog = React.memo(LoginErrorDialog);
export { LoginErrorDialog };
