import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { ISubmitCartResponse } from '../cartsInterfaces';
import { fetchActiveCartThunk } from './fetchActiveCartThunk';

export const submitOrderThunk = createAsyncThunk('carts/submitOrder', async (cartId: number, { dispatch }) => {
  const result = await executeApiRequest<ISubmitCartResponse>(() => httpService.post(`/Cart/${cartId}/Submit`));

  if (!result.requestFailed) {
    dispatch(fetchActiveCartThunk());
  }

  return result.value;
});
