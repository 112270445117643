import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { AuthService } from '../../../utils/auth/AuthService';
import { getIsEmployee } from '../../core';

export const fetchCurrentAccessTokenThunk = createAsyncThunk(
  'auth/fetchCurrentAccessToken',
  async (_, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    try {
      const accountNum = getIsEmployee(state) ? state.auth.employeeSelectedDealer.accountNum : undefined;

      const result = await AuthService.fetchCurrentAccessToken(accountNum);
      // const selectedDealer = state.auth.employeeSelectedDealer;
      return {
        accessToken: result?.accessToken,
        //accountNum: selectedDealer.accountNum,
        decodedToken: result?.decodedToken,
        //name: selectedDealer.name,
        refreshToken: result?.refreshToken
      };
    } catch (error) {
      return rejectWithValue('Failed to fetch current access token');
    }
  }
);
