import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getFiscalYear } from '../../utils/fiscalYearUtils';
import { fetchCurrentAccessTokenThunk, loginThunk, switchDealerThunk } from '../auth';
import { initAppThunk } from '../core';
import { ICashDiscount } from './cashDiscountsInterfaces';
import { _fetchCashDiscountsThunk } from './thunks';

export const cashDiscountsEntityAdapter = createEntityAdapter<{ fiscalYear: string; discounts: ICashDiscount[] }>({
  selectId: x => x.fiscalYear
});

const slice = createSlice({
  initialState: cashDiscountsEntityAdapter.getInitialState(),
  name: 'cashDiscounts',
  reducers: {},
  // eslint-disable-next-line sort-keys
  extraReducers: builder => {
    builder
      .addCase(initAppThunk.fulfilled, (state, action) => {
        cashDiscountsEntityAdapter.upsertOne(state, {
          discounts: action.payload.cashDiscounts,
          fiscalYear: getFiscalYear()
        });
      })
      .addCase(_fetchCashDiscountsThunk.fulfilled, (state, action) => {
        cashDiscountsEntityAdapter.upsertOne(state, {
          discounts: action.payload.cashDiscounts,
          fiscalYear: action.meta.arg
        });
      })
      .addCase(loginThunk.rejected, () => cashDiscountsEntityAdapter.getInitialState())
      .addCase(fetchCurrentAccessTokenThunk.rejected, () => cashDiscountsEntityAdapter.getInitialState())
      .addCase(switchDealerThunk.rejected, () => cashDiscountsEntityAdapter.getInitialState())
      .addCase(switchDealerThunk.pending, () => cashDiscountsEntityAdapter.getInitialState());
  }
});

export const { actions: cashDiscountsActions, reducer: cashDiscountsReducer } = slice;
