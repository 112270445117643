import { IAddress } from '../addresses';
import { IItemBase } from '../catalog';

export interface IActiveCart {
  activeCart: {
    accountNum: string;
    cart?: IActiveCartCart;
    cartId?: number;
    email: string;
  } | null;
  id: string;
  notificationString: string;
  products: IItemBase[] | null;
}

export interface IUpdateCartLineResult {
  cartId: number;
  modifiedAt: string;
  modifiedBy: string;
  cartLines: ICartLine[];
  removedLineIds: number[];
  errors: IUpdateCartLineError[];
  addressId?: number;
  shipMethod?: string;
  shipToName?: string | null;
  termsChecked?: boolean;
  freightCharge?: boolean;
  requestedShipDate?: string;
  description?: string;
  contactId?: string;
}

export interface IUpdateCartLineError {
  itemId: string;
  quantity: number;
  description: string;
  message: string;
}

interface ICartBase {
  axContactId: string | null;
  cartId: number;
  description: string | null;
  createdByEmail: string;
  createdByName: string;
  createdAt: string;
  modifiedAt: string;
  modifiedByEmail: string;
  modifiedByName: string;
  shipMethod: string;
  cartStatusId: number;
  cartTypeId: number;
  submittedUserType?: number;
  submittedBy: string;
  submittedAt: string;
  addressId: number;
  requestedShipDate: string;
  freightCharge: boolean | null;
  termsChecked: boolean | null;
  shipToName: null | string;
  salesOrders: { axSalesId: string; cartId: number }[] | null;
}

export interface IActiveCartCart extends ICartPartial {
  address?: {
    addressId: number;
    addressTypeId: number;
    axRecId: number | null;
    city: string;
    country: string | null;
    countyId: number | null;
    geo: unknown;
    locationTypeId: number;
    name: string;
    state: {
      id: number;
      name: string;
      abbreviation: string;
      country: string;
      type: 'state' | 'province';
      fipsId: string;
    };
    stateId: number;
    street: string;
    street2: string;
    upsValidated: boolean;
    validated: boolean;
    zipcode: string;
  };
}

export interface ICartNormalized extends ICartBase {
  cartLineIds: number[];
}

interface ICartPartial extends ICartBase {
  cartLines: ICartLine[] | null;
  salesOrders: { cartId: number; axSalesId: string }[] | null;
}

export interface ICart extends ICartPartial {
  address?: IAddress;
}

export interface ICartLine {
  cartLineId: number;
  cartId: number;
  itemId: string;
  description?: string;
  quantity: number;
  paymentMethod?: string;
  inventColorId?: string;
  brand?: string;
  price?: number;
  removed?: boolean;
  createdAt?: string;
  createdByEmail?: string;
  modifiedAt?: string;
  modifiedByEmail?: string;
  retailPrice?: number;
  requestedShipDate?: string;
  warehouseId: string;
  inventTransId: string | null;
}

export interface IBulkUpdateCartRequest {
  cartId: number;
  paymentMethod?: string;
  requestedShipDate?: Date;
  preseasonYear?: string;
  preseasonBrand?: string;
  warehouseId?: string;
  shipMethod?: string;
  addressId?: number;
  shipToName?: string;
  termsChecked?: boolean;
  freightCharge?: boolean;
  description?: string;
  contactId?: string;
}

export interface IBulkUpdateItem {
  itemId: string;
  inventColorId?: string;
  quantity: number;
}

export interface IBulkUpdateItemsRequest {
  mergeType: CartLineMergeType;
  items: IBulkUpdateItem[];
}

export enum CartLineMergeType {
  /** Replace any matching cart line with the new value */
  Replace = 1,
  /** If there are any matching item ids, merge the two values */
  Merge,
  /** Remove the item from the cart */
  EmptyCart,
  /** Merge the cart line is there are no errors */
  SafeMerge
}

export interface IGroupedCart {
  brand: string;
  cartLines: ICartLine[];
  paymentMethod: string;
  requestedShipDate: string;
  warehouseId: string;
}

export interface ICartPurchaseOrderNumber {
  cartId: number;
  brand: string;
  paymentMethod: string;
  purchaseOrderNumber: string;
  requestedShipDate: string;
}

export interface ISubmitCartResponse {
  errors: string[];
  isValid: boolean;
  axErrors: string[];
  axValid: boolean;
  salesIds: string[];
}
