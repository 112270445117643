import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { fetchCurrentAccessTokenThunk, loginThunk, switchDealerThunk } from '../auth';
import { IExperlogixConfig } from './configuratorInterfaces';
import {
  createConfigurationThunk,
  deleteConfigurationThunk,
  fetchConfigurationsThunk,
  updateConfigurationThunk
} from './thunks';

export const configAdapter = createEntityAdapter<IExperlogixConfig>({
  selectId: x => x.configurationNumber,
  sortComparer: (a, b) => a.configurationNumber.localeCompare(b.configurationNumber)
});

interface IConfiguratorState {
  configs: EntityState<IExperlogixConfig>;
}

const initialState: IConfiguratorState = {
  configs: configAdapter.getInitialState()
};

const configuratorSlice = createSlice({
  initialState,
  name: 'configurator',
  reducers: {},
  // eslint-disable-next-line sort-keys
  extraReducers: builder => {
    builder
      .addCase(fetchConfigurationsThunk.fulfilled, (state, action) => {
        if (action.payload) {
          configAdapter.upsertMany(state.configs, action.payload);
        }
      })
      .addCase(updateConfigurationThunk.fulfilled, (state, action) => {
        if (action.payload) {
          configAdapter.upsertOne(state.configs, action.payload);
        }
      })
      .addCase(deleteConfigurationThunk.fulfilled, (state, action) => {
        if (action.payload) {
          configAdapter.removeOne(state.configs, action.payload);
        }
      })
      .addCase(createConfigurationThunk.fulfilled, (state, action) => {
        if (action.payload) {
          configAdapter.upsertOne(state.configs, action.payload);
        }
      })
      .addCase(loginThunk.rejected, () => initialState)
      .addCase(fetchCurrentAccessTokenThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.pending, () => initialState);
  }
});

export const { actions: configuratorActions, reducer: configuratorReducer } = configuratorSlice;
