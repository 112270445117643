import { useCallback, useMemo, useState } from 'react';

export interface ILocalStorageSortObject<T extends {}> {
  orderBy: keyof T;
  orderByDirection: 'asc' | 'desc';
}

export function useLocalStorageSort<T extends {}>(
  key: string,
  defaultFilters: ILocalStorageSortObject<T>
): {
  filters: ILocalStorageSortObject<T>;
  setFilters: (field: keyof T) => () => void;
} {
  const [filters, setFilters] = useState<ILocalStorageSortObject<T>>(
    localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key) as string) : defaultFilters
  );

  const handleSet = useCallback(
    (field: keyof T) => () => {
      const updated: ILocalStorageSortObject<T> = {
        ...filters,
        orderBy: field,
        orderByDirection: filters.orderBy === field && filters.orderByDirection === 'asc' ? 'desc' : 'asc'
      };
      localStorage.setItem(key, JSON.stringify(updated));
      setFilters(updated);
    },
    [filters, setFilters, key]
  );

  const values = useMemo(() => ({ filters, setFilters: handleSet }), [filters, handleSet]);

  return values;
}
