import 'styled-components';

export const styledComponentsTheme = {
  breakpoints: {
    mobile: 768
  },
  pallette: {
    brandColors: {
      hydrohoist: '#003263',
      llc: '#00305B',
      poly: '#3a4258',
      rhino: '#becd46',
      shoreline: '#cf1f2c',
      shoremaster: '#0054a6'
    },
    common: {
      danger: '#e74c3c',
      info: '#213f9a',
      success: '#62cb31',
      warning: '#ffb606',
      white: 'white'
    }
  }
} as const;

type Theme = typeof styledComponentsTheme;
declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  export interface DefaultTheme extends Theme {}
}
