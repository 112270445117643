import { createAsyncThunk } from '@reduxjs/toolkit';
import { configSelectors, IExperlogixConfig } from '..';
import { RootState } from '../..';
import { httpService } from '../../../';
import { executeApiRequest } from '../../../utils/asyncUtils';

export const fetchConfigurationsThunk = createAsyncThunk<IExperlogixConfig[], string | undefined>(
  'configurator/fetchConfigurations',
  async (configNumber: string | undefined, { rejectWithValue, getState }) => {
    if (!configNumber || (configNumber && !configSelectors.selectById(getState() as RootState, configNumber))) {
      const response = await executeApiRequest<IExperlogixConfig | IExperlogixConfig[]>(() =>
        httpService.get(`/Configurations${configNumber ? `/${configNumber}` : ''}`)
      );

      if (response.requestFailed) {
        return rejectWithValue('Fetching Configs failed');
      }

      if (response.value) {
        return Array.isArray(response.value) ? response.value : [response.value];
      }
    }

    return [];
  }
);
