import { useEffect } from 'react';
import { fetchLookupsThunk, getNonLoadedOrLoadingLookups, OnDemandLookupTypes } from '../ducks/lookups';
import { useAppDispatch, useAppSelector } from './redux';
import { ILoadingSwitch, useLoadingSwitches } from './useLoadingSwitches';

export const useLookups = (types: OnDemandLookupTypes[]): ILoadingSwitch => {
  const dispatch = useAppDispatch();
  const notLoaded = useAppSelector(state => getNonLoadedOrLoadingLookups(state, types));
  const switches = useLoadingSwitches({ hasError: false, isLoading: true });

  useEffect(
    () => {
      if (notLoaded.length > 0) {
        switches.execute(() => dispatch(fetchLookupsThunk(notLoaded)));
      } else {
        // if there is nothing to load, just resolve the promise
        switches.execute(() => new Promise(resolve => resolve()));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return switches.switches;
};
