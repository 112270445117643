import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthService } from '../../../utils/auth/AuthService';
import { initAppThunk } from '../../core';

export const switchDealerThunk = createAsyncThunk(
  'auth/switchDealer',
  async (dealer: { accountNum: string; name: string }, { rejectWithValue, dispatch }) => {
    const tokens = AuthService.getAuthTokens();

    if (tokens) {
      try {
        await AuthService.refreshAccessToken(tokens.refreshToken, dealer.accountNum);
        AuthService.mostRecentDealer = dealer;
        await dispatch(initAppThunk(false));

        return dealer;
      } catch {
        return rejectWithValue('Failed to refresh acces token');
      }
    } else {
      return rejectWithValue('No refresh token, rejecting');
    }
  }
);
