import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { IOrderMin } from '.';
import { fetchCurrentAccessTokenThunk, loginThunk, switchDealerThunk } from '../auth';
import { IOrder } from './ordersInterfaces';
import { fetchOrdersThunk, fetchOrderThunk } from './thunks';

export const ordersAdapter = createEntityAdapter<IOrder>({
  selectId: x => x.salesId,
  sortComparer: (a, b) => a.salesId.localeCompare(b.salesId)
});

interface IOrdersState {
  minOrders: {
    orders: IOrderMin[];
    totalItems: number;
  };
  orders: EntityState<IOrder>;
}

const initialState: IOrdersState = {
  minOrders: {
    orders: [],
    totalItems: 0
  },
  orders: ordersAdapter.getInitialState()
};

const ordersSlice = createSlice({
  initialState,
  name: 'orders',
  reducers: {},
  // eslint-disable-next-line sort-keys
  extraReducers: builder => {
    builder
      .addCase(fetchOrderThunk.fulfilled, (state, action) => {
        if (action.payload) {
          ordersAdapter.upsertOne(state.orders, action.payload);
        }
      })
      .addCase(fetchOrdersThunk.fulfilled, (state, action) => {
        state.minOrders.orders = action.payload.items;
        state.minOrders.totalItems = action.payload.totalItems;
      })
      .addCase(loginThunk.rejected, () => initialState)
      .addCase(fetchCurrentAccessTokenThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.pending, () => initialState);
  }
});

export const { actions: ordersActions, reducer: ordersReducer } = ordersSlice;
