import * as React from 'react';
import { Modal } from 'react-bootstrap';
const TermsBody = React.lazy(() => import(/* webpackChunkName: 'TermsBody' */ './TermsAndConditionsBody'));

interface ITermsAndConditionsDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

let TermsAndConditionsDialog: React.ComponentType<ITermsAndConditionsDialogProps> = ({ onClose, isOpen }) => {
  return (
    <Modal show={isOpen} onHide={onClose} bsSize="lg">
      <Modal.Body style={{ padding: 0 }}>
        <React.Suspense fallback={<h3 className="text-center">Loading</h3>}>
          <TermsBody onClose={onClose} />
        </React.Suspense>
      </Modal.Body>
    </Modal>
  );
};

TermsAndConditionsDialog = React.memo(TermsAndConditionsDialog);
export { TermsAndConditionsDialog };
