export class HttpError extends Error {
  constructor(status: number, message: string) {
    super(message);

    this.status = status;
  }

  public status: number;
}

export function isHttpError(e: Error): e is HttpError {
  return (e as HttpError).status !== undefined;
}
