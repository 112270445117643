import rg4js, { RaygunOptions, RaygunV2UserDetails } from 'raygun4js';

type Environment = 'test' | 'development' | 'production';

export class ErrorLoggerService {
  private _env: Environment;

  constructor(apiKey: string, environment: Environment) {
    const options: RaygunOptions = {
      excludedHostnames: ['localhost', 'dev01.shoremaster.com']
    };

    this._env = environment;
    rg4js('apiKey', apiKey);
    rg4js('options', options);
    rg4js('enableCrashReporting', true);
    //rg4js('setVersion', env.REACT_APP_VERSION);
    rg4js('withTags', [`env:${this._env}`]);
  }

  public setUser(email: string, name: string) {
    const userDetails: RaygunV2UserDetails = { email, fullName: name, identifier: email, isAnonymous: false };
    rg4js('setUser', userDetails);
  }

  public sendError(e: Error, data?: { tags?: string[]; customData?: Record<string, string | number>[] }) {
    const errorObj: { error: Error; tags?: string[]; customData?: Record<string, string | number>[] } = { error: e };

    if (data?.tags) {
      errorObj.tags = data.tags;
    }

    if (data?.customData) {
      errorObj.customData = data.customData;
    }

    rg4js('send', errorObj);
  }
}
