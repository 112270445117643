import * as React from 'react';
import styled, { DefaultTheme, StyledComponentProps } from 'styled-components';

const StyledButton = styled.button`
  border: none;
  color: inherit;
  font-size: inherit;
  padding: 0;
  outline: none !important;
  text-decoration: none !important;
`;

interface IButtonLinkProps extends StyledComponentProps<'button', DefaultTheme, {}, never> {}

let ButtonLink: React.ComponentType<IButtonLinkProps> = ({ className, ...buttonProps }) => {
  return <StyledButton className={`btn btn-link ${className}`} {...buttonProps} />;
};

ButtonLink = React.memo(ButtonLink);
export { ButtonLink };
