import { createAsyncThunk } from '@reduxjs/toolkit';
import { IAddressMin, IValidatedAddressResult } from '..';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';

export const validateAddressThunk = createAsyncThunk(
  'addresses/validateCart',
  async (address: IAddressMin, { rejectWithValue }) => {
    const result = await executeApiRequest<IValidatedAddressResult>(() =>
      httpService.post('/Addresses/Validate', address)
    );

    if (result.requestFailed) {
      return rejectWithValue('Failed to validate address');
    }

    return result.value;
  }
);
