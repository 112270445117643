import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest, IPagedResponse } from '../../../utils/asyncUtils';
import { IOrderMin, IOrderSearchFilter } from '../ordersInterfaces';

export const fetchOrdersThunk = createAsyncThunk(
  'orders/fetchOrders',
  async (
    { page, take, searchTerm, orderStatus, preSeason, orderBy, orderByDirection }: IOrderSearchFilter,
    { rejectWithValue }
  ) => {
    const response = await executeApiRequest<IPagedResponse<IOrderMin>>(() =>
      httpService.get(
        `/Orders?skip=${
          page === 1 ? 0 : (page - 1) * take
        }&take=${take}&searchTerm=${searchTerm}&orderStatus=${orderStatus}${
          preSeason ? '&preSeason=1' : ''
        }&orderBy=${orderBy}&orderByDirection=${orderByDirection}`
      )
    );
    if (response.requestFailed) {
      return rejectWithValue('Failed to fetch orders');
    }

    return response.value ?? { items: [], totalItems: 0 };
  }
);
