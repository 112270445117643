import { createSelector } from '@reduxjs/toolkit';
import { isWithinInterval, startOfDay } from 'date-fns';
import { RootState } from '../';
import { IFeatureFlags, IMe, UserType } from './coreInterfaces';

export const getMe = createSelector(
  (state: RootState) => state.core.me,
  (me): IMe =>
    me ?? {
      accountNum: '',
      dealerName: 'Unknown',
      email: '',
      iconColor: 'grey',
      name: '?',
      phoneNumber: '',
      userType: UserType.User
    }
);

export const getIsEmployee = createSelector(getMe, me => me.userType === UserType.Employee);

export const getFeatureFlag = createSelector(
  [(state: RootState) => state.core.settings?.featureFlags, (_: RootState, feature?: keyof IFeatureFlags) => feature],
  (flags, feature) => (flags && feature ? flags[feature] : { dealerEnabled: false, employeeEnabled: false })
);

export const getIsDealerSelected = createSelector(
  [getIsEmployee, (state: RootState) => state.auth.employeeSelectedDealer],
  (isEmployee, selected) => !isEmployee || (isEmployee && selected.accountNum)
);

export const getIsPreseasonActive = createSelector(
  [(state: RootState) => state.core.settings?.preSeason, () => startOfDay(new Date()).getTime()],
  (ps, date): boolean =>
    ps ? isWithinInterval(date, { end: new Date(ps.endDate), start: new Date(ps.startDate) }) : false
);

const getPaymentTermObj = createSelector(
  [
    (state: RootState) => state.core.settings?.paymentTerms ?? [],
    (_: RootState, paymentTermId?: string) => paymentTermId
  ],
  (paymentTerms, paymentTermId) => ({
    paymentTerm: paymentTerms.find(x => x.paymentTermId === paymentTermId),
    paymentTermId
  })
);

export const getPaymentTerm = createSelector(getPaymentTermObj, paymentTermObj => paymentTermObj.paymentTerm);

export const getPaymentTermLabel = createSelector(
  getPaymentTermObj,
  ({ paymentTerm, paymentTermId }) => paymentTerm?.description ?? paymentTermId
);
