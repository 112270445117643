import { createSlice } from '@reduxjs/toolkit';
import { fetchCurrentAccessTokenThunk, loginThunk, switchDealerThunk } from '../auth';
import { initAppThunk } from '../core';
import { addressesEntityAdapter } from './addressesEntityAdapters';
import { fetchAddressesThunk, validateAddressThunk } from './thunks';

const addressesSlice = createSlice({
  initialState: addressesEntityAdapter.getInitialState(),
  name: 'addresses',
  reducers: {},
  // eslint-disable-next-line sort-keys
  extraReducers: builder => {
    builder
      .addCase(fetchAddressesThunk.fulfilled, (state, action) => {
        addressesEntityAdapter.upsertMany(state, action.payload);
      })
      .addCase(validateAddressThunk.fulfilled, (state, action) => {
        if (action.payload?.isValid) {
          addressesEntityAdapter.upsertOne(state, action.payload.address);
        }
      })
      .addCase(initAppThunk.fulfilled, (state, action) => {
        const addy = action.payload.activeCart.activeCart?.cart?.address;

        if (addy) {
          addressesEntityAdapter.upsertOne(state, {
            addressId: addy.addressId,
            addressTypeId: addy.addressTypeId,
            axRecId: addy.axRecId,
            businessEntityId: -1,
            city: addy.city,
            latitude: -1,
            longitude: -1,
            primary: false,
            state: addy.state.abbreviation,
            stateId: addy.stateId,
            street: addy.street,
            street2: addy.street2,
            zipcode: addy.zipcode
          });
        }
      })
      .addCase(loginThunk.rejected, () => addressesEntityAdapter.getInitialState())
      .addCase(fetchCurrentAccessTokenThunk.rejected, () => addressesEntityAdapter.getInitialState())
      .addCase(switchDealerThunk.rejected, () => addressesEntityAdapter.getInitialState())
      .addCase(switchDealerThunk.pending, () => addressesEntityAdapter.getInitialState());
  }
});

export const { actions: addressesActions, reducer: addressesReducer } = addressesSlice;
