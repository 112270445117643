import * as msal from 'msal';
const CLIENT_ID = '3e1c2bb4-6d2f-444e-990f-5e5b4fb4f16c';
const SCOPES = ['openid', 'user.read'];
export class EntraService {
  static tokenRequest: msal.AuthenticationParameters = {
    scopes: SCOPES
  };
  static logger: msal.Logger = new msal.Logger(
    (level: msal.LogLevel, message: string) => {
      console.log(message);
    },
    { level: msal.LogLevel.Warning }
  );
  static userAppAgent: msal.UserAgentApplication = new msal.UserAgentApplication(
    {
      auth: {
        clientId: CLIENT_ID,
        navigateToLoginRequestUrl: false,
        redirectUri: `${window.location.origin}/auth.html`
      },
      cache: { cacheLocation: 'localStorage' },
      system: { logger: EntraService.logger }
    }
    // CLIENT_ID,
    // '',
    // EntraService.tokenRecievedCallback,
    // {
    //   cacheLocation: 'localStorage',
    //   logger: EntraService.logger,
    //   navigateToLoginRequestUrl: false,
    //   redirectUri: `${window.location.origin}/auth.html`
    // }
  );
  /**
   * Opens Popup to instigate authentication flow.
   *
   * @returns {Promise<string>} IdToken
   * @memberof EntraService
   */
  public static async openLoginPopup(): Promise<string> {
    let response: msal.AuthResponse;
    try {
      EntraService.cleanUpMsal();
      response = await EntraService.userAppAgent.loginPopup({
        authority: 'https://login.microsoftonline.us/48f9e30e-e044-4f75-aa65-ab3f5f2d8c0c',
        prompt: 'select_account',
        scopes: ['openid', 'user.read']
      });
      return response.idToken.rawIdToken;
    } catch (e) {
      console.error(e);
      //
      return 'error';
    }
  }
  /**
   * Attempt to get the access token either from storage or remotely.
   *
   * @memberof EntraService
   */
  public static async getGraphTokenSilent(): Promise<string> {
    let accessToken = '';
    try {
      const response = await EntraService.userAppAgent.acquireTokenSilent(EntraService.tokenRequest);
      accessToken = response.accessToken;
    } catch (e) {
      console.error('entraService.getGraphTokenSilent', e);
      //
    }
    return accessToken;
  }
  /**
   * Attempt to get the access token either from stoage or remotely.
   *
   * @memberof EntraService
   */
  static async getAccessTokenSilent(): Promise<string> {
    let accessToken = '';
    try {
      const response = await EntraService.userAppAgent.acquireTokenSilent({ scopes: [CLIENT_ID] });
      if (response.accessToken) {
        accessToken = response.accessToken;
      } else {
        return await EntraService.getAccessTokenSilent();
      }
    } catch (e) {
      console.error('EntraService.getAccessTokenSilent', e);
      //
    }
    return accessToken;
  }
  /**
   * Logout and remove all local context
   *
   * @memberof EntraService
   */
  logout(): void {
    EntraService.userAppAgent.logout();
    EntraService.cleanUpMsal();
  }
  /**
   * A method to check whether the user is currently logged in
   *
   * @returns {Promise<boolean>}
   * @memberof EntraService
   */
  async isUserLoggedIn(): Promise<boolean> {
    const token = await EntraService.getAccessTokenSilent();
    return Boolean(token);
  }
  static cleanUpMsal(): void {
    const keys = Object.keys(localStorage).filter(x => {
      const key = x.toLowerCase();
      return key.includes('msal') || key.includes('microsoft');
    });
    keys.forEach(x => localStorage.removeItem(x));
  }
}
