import * as React from 'react';
import styled from 'styled-components';

interface ICopyrightTextProps {}

const StyledDiv = styled.div`
  white-space: nowrap;
`;

let CopyrightText: React.ComponentType<ICopyrightTextProps> = () => {
  const currentYear = React.useMemo(() => new Date().getFullYear(), []);
  return <StyledDiv>&copy; 2015 - {currentYear} Waterfront Brands, LLC</StyledDiv>;
};

CopyrightText = React.memo(CopyrightText);
export { CopyrightText };
