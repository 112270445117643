import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { brandAbbreviationToString } from '../../../utils/brandAbbreviations';
import { coreActions } from '../../core';
import { ISubmitPreSeasonToAxRequest, ISubmitPreSeasonToAxResponse } from '../preSeasonInterfaces';

export const submitPreSeasonToAxThunk = createAsyncThunk(
  'preSeason/submitToAx',
  async (request: { brand: string; cart: ISubmitPreSeasonToAxRequest }, { dispatch, rejectWithValue }) => {
    dispatch(coreActions.toggleWorkingOnItDialog());
    const response = await executeApiRequest<ISubmitPreSeasonToAxResponse>(() =>
      httpService.post(`/Preseason/SubmitToAx`, request.cart)
    );
    dispatch(coreActions.toggleWorkingOnItDialog());

    if (response.requestFailed) {
      toast(`Please retry.`, { type: 'error' });
      return rejectWithValue(`Failed to submit pre-season cart ${request.cart.cartId}`);
    }

    toast(`${brandAbbreviationToString(request.brand)} Pre-Season order submitted.`, { type: 'success' });
    return response.value;
  }
);
