import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { coreActions } from '../../core';
import { IPreSeasonCart } from '../preSeasonInterfaces';

export const recalcPreSeasonOrderThunk = createAsyncThunk(
  'preSeason/calcOrder',
  async (cartId: number, { dispatch, rejectWithValue }) => {
    dispatch(coreActions.toggleWorkingOnItDialog());
    const response = await executeApiRequest<IPreSeasonCart>(() =>
      httpService.post(`/Preseason/${cartId}/Recalculate`)
    );
    dispatch(coreActions.toggleWorkingOnItDialog());

    if (response.requestFailed) {
      return rejectWithValue(`Failed to recalculate pre-season order for cart ${cartId}`);
    }

    return response.value;
  }
);
