import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { IBulkUpdateItemsRequest } from '..';
import { RootState } from '../..';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { coreActions } from '../../core';
import { IUpdateCartLineResult } from '../cartsInterfaces';

export const bulkAddItemsToCartThunk = createAsyncThunk(
  'carts/bulkAddItems',
  async (
    { updateObj, cartId }: { updateObj: IBulkUpdateItemsRequest; cartId?: number },
    { rejectWithValue, dispatch, getState }
  ) => {
    cartId = cartId ?? (getState() as RootState).carts.activeCartId;

    dispatch(coreActions.toggleWorkingOnItDialog());
    const result = await executeApiRequest<IUpdateCartLineResult>(() =>
      httpService.post(`/Cart/${cartId}/BulkAddItems`, updateObj)
    );
    dispatch(coreActions.toggleWorkingOnItDialog());

    if (result.requestFailed) {
      const message = 'Updating failed.  Please retry.';
      toast(message, { type: 'error' });
      return rejectWithValue(message);
    }

    toast(`${result.value?.cartLines.length} items upated/added.`, { type: 'success' });

    return result.value;
  }
);
