import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { IAddress } from '../addressesInterfaces';

export const fetchAddressesThunk = createAsyncThunk('fetchAddresses', async (_, { rejectWithValue }) => {
  const response = await executeApiRequest<IAddress[]>(() => httpService.get('/Addresses'));

  if (response.requestFailed) {
    return rejectWithValue('Failed to fetch addresses');
  }

  return response.value ?? [];
});
