import * as React from 'react';
import classes from './Spinner.module.scss';

interface ISpinnerProps {}

let Spinner: React.ComponentType<ISpinnerProps> = () => {
  return (
    <div className={classes.spinner}>
      <div className={classes.rect1} />
      <div className={classes.rect2} />
      <div className={classes.rect3} />
      <div className={classes.rect4} />
      <div className={classes.rect5} />
    </div>
  );
};

Spinner = React.memo(Spinner);
export { Spinner };
