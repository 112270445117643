/* eslint-disable @typescript-eslint/ban-types */
import { HttpError, isHttpError } from './customErrors/HttpError';

export interface IApiExceptionResponse {
  exceptionMessage: string;
  exceptionType: string;
  message: string;
  stackTrace: string;
}

export interface IHttpRequest {
  type: 'post' | 'get' | 'put' | 'delete';
  url: string;
}

export class HttpService {
  constructor({
    baseUrl,
    accessTokenFunc,
    errorLogFunc,
    logoutFunc
  }: {
    baseUrl: string;
    accessTokenFunc: () => Promise<string | undefined>;
    errorLogFunc: (error: Error, data?: { tags?: string[]; customData?: Record<string, string | number>[] }) => void;
    logoutFunc: () => void;
  }) {
    this.url = baseUrl;
    this.errorLogFunc = errorLogFunc;
    this.fetchAccessTokenFunc = accessTokenFunc;
    this.logoutFunc = logoutFunc;
  }

  public readonly url: string;
  private readonly fetchAccessTokenFunc: () => Promise<string | undefined>;
  private readonly logoutFunc: () => void;
  private readonly errorLogFunc: (
    error: Error,
    data?: { tags?: string[]; customData?: Record<string, string | number>[] }
  ) => void;
  // private pendingRequests: IHttpRequest[] = [];
  // private retryCount: number = 0;

  public createQueryString(key: string, values: Array<string | number>, includePrefix: boolean = true): string {
    if (values.length > 0) {
      return `${includePrefix ? '&' : ''}${values.map(x => `${key}=${x}`).join('&')}`;
    }
    return '';
  }

  public async downloadFile(fileUrl: string, fileName: string = 'test'): Promise<void> {
    const settings: RequestInit = {
      method: 'get'
    };

    const response = await this.executeRequest(fileUrl, settings);

    const blob = await response.blob();

    const objectUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = objectUrl;
    anchor.download = fileName;
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
  }

  public async get(url: string): Promise<Response> {
    const settings: RequestInit = {
      method: 'get'
    };

    return this.executeRequest(url, settings);
  }

  public async post(url: string, payload: string | object | number = ''): Promise<Response> {
    if (typeof payload !== 'string') {
      payload = JSON.stringify(payload);
    }

    const headers: Headers = new Headers();
    headers.append('Content-Type', 'Application/json');

    const settings: RequestInit = {
      body: payload,
      headers,
      method: 'post'
    };

    return this.executeRequest(url, settings);
  }

  public async postFormData(url: string, payload: FormData): Promise<Response> {
    const settings: RequestInit = {
      body: payload,
      method: 'post'
    };

    return this.executeRequest(url, settings);
  }

  public async put(url: string, payload: unknown): Promise<Response> {
    const stringedPayload = JSON.stringify(payload);

    const headers: Headers = new Headers();
    headers.append('Content-Type', 'Application/json');

    const settings: RequestInit = {
      body: stringedPayload,
      headers,
      method: 'put'
    };

    return this.executeRequest(url, settings);
  }

  public async delete(url: string, payload: string | object = ''): Promise<Response> {
    if (payload !== '') {
      payload = JSON.stringify(payload);
    }

    const headers: Headers = new Headers();
    headers.append('Content-Type', 'Application/json');

    const settings: RequestInit = {
      body: payload,
      headers,
      method: 'delete'
    };

    return this.executeRequest(url, settings);
  }

  private async executeRequest(url: string, settings: RequestInit): Promise<Response> {
    //const accessToken = sessionStorage.getItem(LocalStorgeVars.ACCESS_TOKEN_KEY);
    const accessToken = await this.fetchAccessTokenFunc();
    if (!accessToken) {
      this.logoutFunc();

      throw new Error('Unable to fetch new access token, logging user out');
    }

    const fullUrl = `${this.url}${url}`;

    if (!settings.headers) {
      settings.headers = new Headers();
    }

    (settings.headers as Headers).append('Authorization', `Bearer ${accessToken}`);

    const response = await fetch(fullUrl, settings);

    if (response.ok) {
      return response;
    }

    try {
      throw await this.createHttpError(response);
    } catch (e) {
      let sendError = true;
      const customData: Record<string, string | number>[] = [{ apiEndpoint: url }];

      if (e instanceof Error) {
        if (isHttpError(e)) {
          customData.push({ httpStatus: e.status });
          if (e.status === 401) {
            sendError = false;
            this.logoutFunc();
          }
        }
        if (sendError) {
          this.errorLogFunc(e, { customData, tags: ['apiException'] });
        }
      }
      // if the status code if 401 unauthorized log them out
      throw e;
    }
  }

  private async createHttpError(response: Response): Promise<HttpError> {
    let message = 'Unknown error';

    try {
      const exception = (await response.json()) as IApiExceptionResponse;

      if (exception.exceptionMessage) {
        message = exception.exceptionMessage;
      } else if (exception.message) {
        message = exception.message;
      }
    } catch (e) {
      // grab any errors with desierializing JSON, we don't need to do anything with them
    }

    return new HttpError(response.status, message);
  }
}
