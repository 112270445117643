import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { IUpdateCartLineResult } from '../../carts/cartsInterfaces';

export interface IAddPreseasonItemRequest {
  itemId: string;
  inventColorId: string | undefined;
  quantity: number;
}

export const updatePreSeasonItemThunk = createAsyncThunk(
  'preSeason/updateItem',
  async (request: IAddPreseasonItemRequest, { rejectWithValue }) => {
    const result = await executeApiRequest<IUpdateCartLineResult>(() =>
      httpService.post(`/Preseason/UpdateItem`, request)
    );

    if (result.requestFailed) {
      const message = `Adding item ${request.itemId} failed.  Please retry.`;
      toast(message, { type: 'error' });
      return rejectWithValue(message);
    }

    toast(`Item ${request.itemId} updated successfully`, { type: 'success' });

    return result.value;
  }
);
