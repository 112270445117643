import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { IExperlogixConfig } from '../configuratorInterfaces';

export const createConfigurationThunk = createAsyncThunk(
  'configurator/init',
  async (
    values: { allDealers: boolean; smRecommended: boolean } | undefined = { allDealers: false, smRecommended: false },
    { rejectWithValue }
  ) => {
    const response = await executeApiRequest<IExperlogixConfig>(() =>
      httpService.post(`/Configurations?smCreated=${values.smRecommended}&all=${values.allDealers}`)
    );

    if (response.requestFailed) {
      return rejectWithValue('Failed to initialize a new Experlogix config');
    }

    return response.value;
  }
);
