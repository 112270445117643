import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPreSeasonWorksheetRequest, IPreseasonWorksheetResult } from '..';
import { httpService } from '../../..';
import { executeApiRequest, IEditObject } from '../../../utils/asyncUtils';

export const submitPreSeasonWorksheetThunk = createAsyncThunk(
  'preSeason/submitWorksheet',
  async (worksheet: IPreSeasonWorksheetRequest, { rejectWithValue }) => {
    const result = await executeApiRequest<IEditObject<IPreseasonWorksheetResult>>(() =>
      httpService.post(`/Preseason/ProcessWorksheet`, worksheet)
    );

    if (result.requestFailed) {
      return rejectWithValue('Failed to update pre-season worksheet');
    }

    return result.value;
  }
);
