import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { IExperlogixConfig } from '../configuratorInterfaces';

export const updateConfigurationThunk = createAsyncThunk(
  'configurator/update',
  async (config: IExperlogixConfig, { rejectWithValue }) => {
    const response = await executeApiRequest<IExperlogixConfig>(() => httpService.put('/Configurations', config));

    if (response.requestFailed) {
      return rejectWithValue(`Failed to updated ${config.configurationNumber}`);
    } else if (response.value) {
      return response.value;
    }
  }
);
