import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { ILookupApiResponse } from '../lookupsInterfaces';

export const fetchLookupsThunk = createAsyncThunk(
  'lookups/fetch',
  async (types: Array<keyof ILookupApiResponse>, { rejectWithValue, getState }) => {
    const state = getState() as RootState;

    const toLoad = types.filter(x => !state.lookups.loaded.includes(x));

    if (toLoad.length > 0) {
      const response = await executeApiRequest<ILookupApiResponse>(() =>
        httpService.get(`/Lookups?${httpService.createQueryString('types', toLoad, false)}`)
      );

      if (response.requestFailed) {
        return rejectWithValue('Failed to fetch lookups');
      }

      return response.value ?? {};
    }

    return {};
  }
);
