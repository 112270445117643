export interface IMe {
  accountNum: string | null;
  dealerName: string | null;
  email: string;
  iconColor: string;
  name: string;
  phoneNumber: string;
  userType: number;
}

export const UserType = {
  Admin: 2,
  Employee: 1,
  User: 3
} as const;

export interface IConfiguratorAccess {
  enabled: boolean;
  dealerDisabled: boolean;
  url: string;
}

export interface ISettings {
  experlogix: IConfiguratorAccess;
  freeShipping: IFreeShippingAccess;
  paymentTerms: IPaymentTerm[];
  preSeason: { startDate: string; endDate: string; fiscalYear: string };
  shipping: {
    canCanadaDropShip: boolean;
    shipMethods: { label: string; value: string; parcelShipMethod: boolean }[];
  };
  featureFlags: IFeatureFlags;
  footerLogos: { order: number; brandId: number }[];
}

export interface IPaymentTerm {
  cashDiscount: boolean;
  description: string;
  paymentTermId: string;
}

export interface IFreeShippingAccess {
  dealerEnabled: boolean;
  validShipMethods: string[];
  validRange: { startDate: string; endDate: string };
}

export interface IFeatureFlags {
  newsFeed: IFeatureFlag;
  catalog: IFeatureFlag;
  configurator: IFeatureFlag;
  preSeason: IFeatureFlag;
  carts: IFeatureFlag;
  orders: IFeatureFlag;
  logisticsSupport: IFeatureFlag;
  salesSupport: IFeatureFlag;
  warrantySupport: IFeatureFlag;
}

interface IFeatureFlag {
  dealerEnabled: boolean;
  employeeEnabled: boolean;
}
