import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { coreActions } from '../../core';
import { IBulkUpdateCartRequest, IUpdateCartLineResult } from '../cartsInterfaces';

export const bulkUpdateCartThunk = createAsyncThunk(
  'carts/bulkUpdate',
  async (
    { request, disableSpinner }: { request: IBulkUpdateCartRequest; disableSpinner?: boolean },
    { rejectWithValue, dispatch }
  ) => {
    if (!disableSpinner) {
      dispatch(coreActions.toggleWorkingOnItDialog());
    }
    const response = await executeApiRequest<IUpdateCartLineResult>(() =>
      httpService.post(`/Cart/${request.cartId}/BulkUpdate`, request)
    );
    if (!disableSpinner) {
      dispatch(coreActions.toggleWorkingOnItDialog());
    }

    if (response.requestFailed) {
      toast('There was a problem updating the cart.  Please Retry', { type: 'error' });
      return rejectWithValue('Failed to bulk update cart');
    }

    if (request.paymentMethod !== undefined) {
      toast(`Updated Cart Payment Methods`, { type: 'success' });
    }
    if (request.contactId !== undefined) {
      toast(`Updated Cart contact`, { type: 'success' });
    }
    if (request.requestedShipDate !== undefined) {
      toast(`Updated Cart Requested Ship Date`, { type: 'success' });
    }
    if (request.addressId !== undefined) {
      toast(`Updated Cart address`, { type: 'success' });
    }
    if (request.shipMethod !== undefined) {
      toast(`Updated ship method`, { type: 'success' });
    }
    if (request.warehouseId !== undefined) {
      toast(`Updated Warehouse to ${request.warehouseId}`, { type: 'success' });
    }

    return response.value;
  }
);
