import { createSelector } from '@reduxjs/toolkit';
import isWithinInterval from 'date-fns/esm/isWithinInterval';
import { RootState } from '..';
import { notUndefined } from '../../utils/objectUtils';
import { brandSelectors } from '../lookups';
import { CartStatus } from './cartEnums';
import { ICart, ICartLine } from './cartsInterfaces';

export const _getCartLines = (cartLines: Record<number, ICartLine | undefined>, cartId: number) =>
  Object.values(cartLines)
    .filter(x => x?.cartId === cartId && !x.removed)
    .filter(notUndefined);

export const getCarts = createSelector(
  [(state: RootState) => state.carts, (state: RootState) => state.addresses.entities],
  (carts, addressHash): ICart[] => {
    return Object.values(carts.carts)
      .filter(notUndefined)
      .filter(x => x.cartStatusId === CartStatus.Active || x.cartStatusId === CartStatus.Inactive) // make sure it's active or inactive
      .map<ICart>(x => ({
        ...x,
        address: x.addressId ? addressHash[x.addressId] : undefined,
        cartLines: x.cartLineIds.map<ICartLine | undefined>(y => carts.cartLines[y]).filter(notUndefined),
        salesOrders: null
      }));
  }
);

export const getCart = createSelector(
  [
    (state: RootState) => state.carts.carts,
    (state: RootState) => state.carts.cartLines,
    (_: RootState, cartId: number) => cartId
  ],
  (carts, cartLines, cartId) => {
    const cartMin = carts[cartId];

    if (cartMin) {
      const cart: ICart = {
        ...cartMin,
        cartLines: _getCartLines(cartLines, cartId)
      };

      return cart;
    }
  }
);

export const getActiveCart = createSelector(
  [
    (state: RootState) => state.carts.activeCartId,
    (state: RootState) => state.carts.cartLines,
    (state: RootState) => state.carts.carts,
    (state: RootState) => state.addresses.entities
  ],
  (activeCartId, cartLines, carts, addressHash): ICart | undefined => {
    if (activeCartId) {
      const activeCartBase = carts[activeCartId];

      return activeCartBase
        ? {
            ...activeCartBase,
            address: activeCartBase.addressId ? addressHash[activeCartBase.addressId] : undefined,
            cartLines: activeCartBase.cartLineIds
              .map(x => cartLines[x])
              .filter(notUndefined)
              .filter(x => x && !x.removed),
            salesOrders: null
          }
        : undefined;
    }
    return undefined;
  }
);

export const getActiveCartLines = createSelector(
  getActiveCart,
  (activeCart): ICartLine[] => activeCart?.cartLines ?? []
);

export const getCartItemCount = createSelector(
  [
    (state: RootState) => state.carts.cartLines,
    (state: RootState) => state.carts.carts,
    (_: RootState, request: { cartId: number; itemId: string }) => request
  ],
  (cartLinesHash, cartsHash, { cartId, itemId }) => {
    const cartLineIds = cartsHash[cartId]?.cartLineIds ?? [];

    return cartLineIds.reduce<number>((total, cartLineId) => {
      const line = cartLinesHash[cartLineId];

      return line && line.itemId === itemId ? (total += line.quantity) : total;
    }, 0);
  }
);

export const getCartLineTotal = createSelector(
  [
    (state: RootState) => state.cashDiscounts.entities,
    (state: RootState) => state.core.settings?.paymentTerms ?? [],
    (state: RootState) => Object.values(state.catalog.items.entities),
    (_: RootState, cartLine: ICartLine) => cartLine
  ],
  (cashDiscounts, paymentTerms, products, cartLine): number => {
    const baseCartLineTotal = cartLine.price ? cartLine.price * cartLine.quantity : 0;
    const isPaymentDiscountable =
      paymentTerms.find(x => x.paymentTermId === cartLine.paymentMethod)?.cashDiscount ?? false;
    const isItemDiscountDisabled = products.find(x => x?.cashDiscountDisabled ?? false);

    if (!isPaymentDiscountable || isItemDiscountDisabled) {
      return baseCartLineTotal;
    }

    const requestedShipDate = cartLine.requestedShipDate ? new Date(cartLine.requestedShipDate) : undefined;

    const cashDiscount = requestedShipDate
      ? Object.values(cashDiscounts)
          .flatMap(x => x?.discounts ?? [])
          .find(x =>
            isWithinInterval(requestedShipDate, {
              end: new Date(x.endDate),
              start: new Date(x.startDate)
            })
          )
      : undefined;

    return cashDiscount ? baseCartLineTotal * (1 - cashDiscount.amount / 100) : baseCartLineTotal;
  }
);

export const getActiveCartTotal = createSelector(
  [(state: RootState) => state, getActiveCartLines],
  (state, lines): number => lines.reduce((total, line) => (total += getCartLineTotal(state, line)), 0)
);

export const getCartProducts = createSelector(
  [
    (state: RootState) => state.carts.carts,
    (state: RootState) => state.carts.cartLines,
    (state: RootState) => state.catalog.items.entities,
    (_: RootState, cartId: number) => cartId
  ],
  (cartHash, cartLineHash, productHash, cartId) => {
    const cartLineIds = cartHash[cartId]?.cartLineIds ?? [];
    const cartLines = cartLineIds
      .map(x => cartLineHash[x])
      .filter(notUndefined)
      .filter(x => !x.removed);
    const products = cartLines.map(x => productHash[x.itemId]).filter(notUndefined);

    return products;
  }
);

export const getActiveCartLinesByItemId = createSelector(
  [getActiveCartLines, (_: RootState, itemId: string) => itemId],
  (cartLines, ItemId): ICartLine[] => cartLines.filter(x => x.itemId === ItemId)
);

export const getIsItemInCart = createSelector(getActiveCartLinesByItemId, (cartLines): boolean => cartLines.length > 0);

export const getCartLines = createSelector(
  [(state: RootState) => state.carts.cartLines, (_: RootState, cartId: number) => cartId],
  _getCartLines
);

export const getIsCartSubmitted = createSelector(
  [(state: RootState) => state.carts.carts, (_: RootState, cartId: number) => cartId],
  (carts, cartId): boolean => {
    const cart = carts[cartId];

    return cart?.cartStatusId === 3;
  }
);

export const getCartTotal = createSelector([(state: RootState) => state, getCartLines], (state, lines): number =>
  lines.reduce((total, line) => (total += getCartLineTotal(state, line)), 0)
);

export const getCartBrandBreakdown = createSelector([brandSelectors.selectAll, getCartLines], (brands, cartLines) => {
  const summed = cartLines.reduce<Record<string, number>>((hash, line) => {
    if (line && line.price && line.brand) {
      const amount = line.quantity * line.price;
      if (!hash[line.brand]) {
        hash[line.brand] = 0;
      }

      hash[line.brand] = hash[line.brand] + amount;
    }
    return hash;
  }, {});

  return brands.reduce<Record<number, number>>((obj, item) => {
    obj[item.id] = summed[item.abbreviation] ? summed[item.abbreviation] : 0;
    return obj;
  }, {});
});
