import { createAsyncThunk } from '@reduxjs/toolkit';
import { IActiveCart } from '../';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';

export const fetchActiveCartThunk = createAsyncThunk('carts/fetchActive', async (_, { rejectWithValue }) => {
  const result = await executeApiRequest<IActiveCart>(() => httpService.get('/Cart/ActiveCart'));

  if (result.requestFailed) {
    rejectWithValue('Failed to fetch active cart');
  }

  return result.value;
});
