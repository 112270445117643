import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import { notUndefined } from '../../utils/objectUtils';
import { getActiveCartLines } from '../carts';
import { catalogEntityAdapters } from './catalogEntityAdapters';
import { ICatalogFilter, IColor, IItem, ISubPartItem } from './catalogInterfaces';

export const catalogSelectors = catalogEntityAdapters.items.getSelectors((state: RootState) => state.catalog.items);
export const priceSelectors = catalogEntityAdapters.prices.getSelectors((state: RootState) => state.catalog.prices);

export const getIsCashDiscountDisabled = createSelector(
  [(state: RootState) => state.catalog.items, (_: RootState, itemId: string) => itemId],
  (itemHash, itemId) => itemHash.entities[itemId]?.cashDiscountDisabled ?? false
);

export const getItemColors = createSelector(
  [
    (state: RootState) => state.catalog.items,
    (state: RootState) => state.catalog.colors,
    (_: RootState, itemId: string) => itemId
  ],
  (items, colors, itemId): IColor[] =>
    items.entities[itemId]?.colors?.map(x => colors.entities[x]).filter(notUndefined) ?? []
);

export const getActiveCartProducts = createSelector(
  [getActiveCartLines, (state: RootState) => state.catalog.items.entities],
  (cartLines, itemHash) => cartLines.map(x => itemHash[x.itemId]).filter(notUndefined)
);

export const getCatalogPage = createSelector(
  [
    (state: RootState) => state.catalog.pageHash,
    (state: RootState) => state.catalog.items.entities,
    (_: RootState, filters: ICatalogFilter) => filters
  ],
  (pageHash, itemHash, filters): IItem[] => {
    const key = createCatalogFilterKey(filters);

    return pageHash[key]?.pages[filters.page]?.map(x => itemHash[x]).filter(notUndefined) ?? [];
  }
);

export const getProducts = createSelector(
  [(state: RootState) => state.catalog.items.entities, (_: RootState, itemIds: string[]) => itemIds],
  (itemHash, itemIds): IItem[] => itemIds.map(x => itemHash[x]).filter(notUndefined)
);

export const getCatalogTotalItems = createSelector(
  [(state: RootState) => state.catalog.pageHash, (_: RootState, filters: ICatalogFilter) => filters],
  (pageHash, filters): number => {
    const key = createCatalogFilterKey(filters);

    return pageHash[key]?.totalItems ?? 0;
  }
);

export const getItemSubParts = createSelector(
  [
    (state: RootState) => state.catalog.subPartHash,
    (state: RootState) => state.catalog.items.entities,
    (_: RootState, itemId: string) => itemId
  ],
  (subPartHash, items, itemId): ISubPartItem[] => {
    const partNumArr = subPartHash[itemId] ?? [];

    return partNumArr
      .map<ISubPartItem | undefined>(x => {
        const item = items[x.itemId];
        return item ? { ...item, qtyRequired: x.qtyRequired } : undefined;
      })
      .filter(notUndefined);
  }
);

export const createCatalogFilterKey = ({ orderBy, productFamilyId, searchString }: ICatalogFilter): string =>
  `orderBy=${orderBy}|productFamilyId=${productFamilyId}|searchString=${searchString}`;
