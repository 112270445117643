import { useCallback, useState } from 'react';

/** A hook to encapsulate the logic for opening and closing a dialog */
export function useDialog<T extends string, V extends {} = {}>(): [
  { type: T; value: V } | undefined,
  (type: T) => (value?: V) => void,
  () => void
] {
  const [dialog, setDialog] = useState<{ type: T; value: V } | undefined>();

  const openDialog = useCallback((type: T) => (value: V = {} as V) => setDialog({ type, value }), []);
  const closeDialog = useCallback(() => setDialog(undefined), []);

  return [dialog, openDialog, closeDialog];
}
