import { configureStore, DeepPartial } from '@reduxjs/toolkit';
import { rootReducer, RootState } from './ducks';

export const configRedux = (preloadedState?: DeepPartial<RootState>) => {
  return configureStore({
    devTools: {
      shouldHotReload: true
    },
    preloadedState,
    reducer: rootReducer
  });
};
