import { isAsyncThunkAction, unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import { errorLogger } from '..';

export interface ILoadingSwitch {
  isLoading: boolean;
  hasError: boolean;
}

export const useLoadingSwitches = <T extends {}>(
  defaultValues: ILoadingSwitch = { hasError: false, isLoading: false }
): {
  execute: (func: () => Promise<T | void>) => Promise<T | void>;
  switches: ILoadingSwitch;
} => {
  const [loading, setLoading] = React.useState<ILoadingSwitch>(defaultValues);
  const executeRef = React.useRef(async (func: () => Promise<T | void>) => {
    setLoading({ hasError: false, isLoading: true });
    let hasError = false;
    try {
      const result = await func();

      // we check if it is a Redux-Toolkit thunk
      // according to their page, they don't classify a 'rejectWithValue' to be an
      // exception, so we handle it here
      if (isAsyncThunkAction(result)) {
        try {
          // unwrap the result to check for error
          unwrapResult(result);
        } catch (error) {
          errorLogger.sendError(error, { tags: ['useLoadingSwitch'] });
          hasError = true;
        }
      }
    } catch (error) {
      errorLogger.sendError(error, { tags: ['useLoadingSwitch'] });
      console.error(error);
      hasError = true;
    }

    setLoading({ hasError, isLoading: false });
  });

  const value = React.useMemo(
    () => ({
      execute: executeRef.current,
      switches: loading
    }),
    [loading]
  );

  return value;
};
