import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUpdateCartLineResult } from '../';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { coreActions } from '../../core';

export const splitLineThunk = createAsyncThunk(
  'carts/splitLine',
  async ({ cartLineId, newQuantity }: { cartLineId: number; newQuantity: number }, { rejectWithValue, dispatch }) => {
    dispatch(coreActions.toggleWorkingOnItDialog());
    const response = await executeApiRequest<IUpdateCartLineResult>(() =>
      httpService.post(`/CartLines/${cartLineId}/Split`, newQuantity)
    );
    dispatch(coreActions.toggleWorkingOnItDialog());

    if (response.requestFailed) {
      return rejectWithValue('Failed to split cart line');
    }

    return response.value;
  }
);
