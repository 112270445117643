import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../..';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { coreActions } from '../../core';
import { fetchActiveCartThunk } from './fetchActiveCartThunk';

export const deleteCartThunk = createAsyncThunk(
  'carts/delete',
  async (cartId: number, { dispatch, rejectWithValue, getState }) => {
    dispatch(coreActions.toggleWorkingOnItDialog());
    const response = await executeApiRequest(() => httpService.delete(`/Cart/${cartId}`), false);

    if (cartId === (getState() as RootState).carts.activeCartId) {
      await dispatch(fetchActiveCartThunk());
    }

    dispatch(coreActions.toggleWorkingOnItDialog());

    if (response.requestFailed) {
      const message = `Failed to delete cart ${cartId}.`;

      toast(message, { type: 'error' });
      return rejectWithValue(message);
    }

    toast(`Sucessfully removed cart ${cartId}`, { type: 'info' });

    return cartId;
  }
);
