import { AuthProviders, IOauthConfig } from './authInterfaces';

export const oauthConfigs: Record<AuthProviders, IOauthConfig> = {
  adfs: {
    authorizationEndpoint: 'https://sts.shoremaster.com/adfs/oauth2/authorize',
    clientId: '23b514ef-7266-4ffd-8ee6-d8a6671d3383',
    oauthType: '2.0',
    popupOptions: { height: 500, width: 400 },
    redirectUri: window.location.origin || window.location.protocol + '//' + window.location.host,
    resource: 'urn:dealer_portal'
  },
  google: {
    authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth',
    clientId: '408057365987-3ig01pevg414keg3lib61trgdor4i3je.apps.googleusercontent.com',
    oauthType: '2.0',
    popupOptions: { height: 633, width: 452 },
    redirectUri: window.location.origin,
    scope: ['profile', 'email']
  },
  twitter: {
    authorizationEndpoint: 'https://api.twitter.com/oauth/authenticate',
    oauthType: '1.0',
    popupOptions: { height: 645, width: 495 },
    redirectUri: window.location.origin
  }
};
