import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCurrentAccessTokenThunk } from '../auth/thunks/fetchCurrentAccessTokenThunk';
import { loginThunk } from '../auth/thunks/loginThunk';
import { logoutThunk } from '../auth/thunks/logoutThunk';
import { switchDealerThunk } from '../auth/thunks/switchDealerThunk';
import { IMe, ISettings } from './coreInterfaces';
import { initAppThunk } from './thunks';

interface ICoreState {
  appInitialized: boolean;
  appInitializing: boolean;
  me: IMe | undefined;
  settings: ISettings | undefined;
  ui: {
    showActiveCartMessageDialog: string | undefined;
    showMessageDialog: string | undefined;
    showWorkingOnIt: boolean;
  };
}

const initialState: ICoreState = {
  appInitialized: false,
  appInitializing: false,
  me: undefined,
  settings: undefined,
  ui: {
    showActiveCartMessageDialog: undefined,
    showMessageDialog: undefined,
    showWorkingOnIt: false
  }
};

const coreSlice = createSlice({
  initialState,
  name: 'core',
  reducers: {
    toggleActiveCartMessageDialog: (state, action: PayloadAction<string | undefined>) => {
      state.ui.showActiveCartMessageDialog = action.payload;
    },
    toggleMessageDialog: (state, action: PayloadAction<string | undefined>) => {
      state.ui.showMessageDialog = action.payload;
    },
    toggleWorkingOnItDialog: state => {
      state.ui.showWorkingOnIt = !state.ui.showWorkingOnIt;
    }
  },
  // eslint-disable-next-line sort-keys
  extraReducers: builder => {
    builder
      .addCase(initAppThunk.pending, (state, action) => {
        state.appInitializing = action.meta.arg;
      })
      .addCase(initAppThunk.fulfilled, (state, action) => {
        state.appInitialized = true;
        state.appInitializing = false;
        state.me = action.payload?.me;
        state.settings = action.payload?.settings;
      })
      .addCase(initAppThunk.rejected, state => {
        state.appInitialized = false;
        state.appInitializing = false;
      })
      .addCase(loginThunk.rejected, () => initialState)
      .addCase(fetchCurrentAccessTokenThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.rejected, () => initialState)
      .addCase(logoutThunk.fulfilled, () => initialState);
  }
});

export const { actions: coreActions, reducer: coreReducer } = coreSlice;
