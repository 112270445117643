import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { IUpdateCartLineResult } from '../';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { coreActions } from '../../core';

export const deleteCartLineThunk = createAsyncThunk(
  'carts/deleteCartLine',
  async (cartLineId: number, { rejectWithValue, dispatch }) => {
    dispatch(coreActions.toggleWorkingOnItDialog());
    const response = await executeApiRequest<IUpdateCartLineResult>(() =>
      httpService.delete(`/CartLines/${cartLineId}`)
    );
    dispatch(coreActions.toggleWorkingOnItDialog());

    if (response.requestFailed) {
      toast(`There was a problem removing the cart line.  Please retry.`, { type: 'error' });
      return rejectWithValue('Failed To delete cartline');
    }

    toast('Cart Line removed successfully.', { type: 'info' });
    return response.value;
  }
);
