import { SyntheticEvent, useCallback, useState } from 'react';

/** A hook to encapsulate the logic for toggling an anchor element */
export function useAnchorEl(): [HTMLElement | undefined, (e: SyntheticEvent<HTMLElement>) => void, () => void] {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const open = useCallback((e: SyntheticEvent<HTMLElement>) => setAnchorEl(e.currentTarget), []);
  const close = useCallback(() => setAnchorEl(undefined), []);

  return [anchorEl, open, close];
}
