import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { IPreSeasonApproval } from '..';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';

export interface IUpdateApprovalRequest {
  cartId: number;
  approvalTypeId: number;
  status: boolean;
}

export const updatePreSeasonApprovalThunk = createAsyncThunk(
  'preSeason/updateApproval',
  async (request: IUpdateApprovalRequest, { rejectWithValue }) => {
    const response = await executeApiRequest<IPreSeasonApproval>(() =>
      httpService.post(`/Preseason/UpdateApproval`, request)
    );

    if (response.requestFailed) {
      toast(`Updating approval status failed.  Please retry.`, { type: 'error' });

      return rejectWithValue(
        `Failed to update pre-season approval, ${request.cartId} ${request.approvalTypeId} ${request.status}`
      );
    }

    toast('Approval status updated.', { type: 'success' });

    return response.value;
  }
);
