import { createSlice, EntityState } from '@reduxjs/toolkit';
import { IColor, IItem } from '.';
import { fetchCurrentAccessTokenThunk, loginThunk, switchDealerThunk } from '../auth';
import { initAppThunk } from '../core';
import { fetchCatalogSectionThunk } from '../preSeason';
import { catalogEntityAdapters } from './catalogEntityAdapters';
import { IApiItem, IItemInstruction, IProductPrice } from './catalogInterfaces';
import { createCatalogFilterKey } from './catalogSelectors';
import {
  fetchInstructionsThunk,
  fetchItemThunk,
  fetchPricesThunk,
  fetchProductColorsThunk,
  fetchSubPartsThunk
} from './thunks';
import { fetchCatalogThunk } from './thunks/fetchCatalogThunk';

interface ICatalogState {
  colors: EntityState<Omit<IColor, 'itemId'>>;
  items: EntityState<IItem>;
  prices: EntityState<{ itemId: string; prices: IProductPrice[] }>;
  pageHash: Record<string, { totalItems: number; pages: Record<number, string[] | undefined> } | undefined>;
  subPartHash: Record<string, { itemId: string; qtyRequired: number }[] | undefined>;
  instructions: Record<string, IItemInstruction[] | undefined>;
}

const initialState: ICatalogState = {
  colors: catalogEntityAdapters.colors.getInitialState(),
  instructions: {},
  items: catalogEntityAdapters.items.getInitialState(),
  pageHash: {},
  prices: catalogEntityAdapters.prices.getInitialState(),
  subPartHash: {}
};

const processProducts = (products: IApiItem[]): { products: IItem[]; colors: IColor[] } => {
  return {
    colors: products
      .flatMap(x => x.colors ?? [])
      .map<IColor>(x => ({ inventColorId: x.inventColorId, name: x.name })),
    products: products.map<IItem>(x => ({ ...x, colors: x.colors?.map(y => y.inventColorId) }))
  };
};

const catalogSlice = createSlice({
  initialState,
  name: 'catalog',
  reducers: {},
  // eslint-disable-next-line sort-keys
  extraReducers: builder => {
    builder
      .addCase(initAppThunk.fulfilled, (state, action) => {
        if (action.payload.activeCart.products) {
          const updates = processProducts(action.payload.activeCart.products);
          catalogEntityAdapters.colors.upsertMany(state.colors, updates.colors);
          catalogEntityAdapters.items.upsertMany(state.items, updates.products);
        }
      })
      .addCase(fetchInstructionsThunk.fulfilled, (state, action) => {
        state.instructions = {
          ...state.instructions,
          ...action.payload.reduce<Record<string, IItemInstruction[]>>((hash, item) => {
            hash[item.itemId] = hash[item.itemId] ? [...hash[item.itemId], item] : [item];

            return hash;
          }, {})
        };
      })
      .addCase(fetchPricesThunk.fulfilled, (state, action) => {
        catalogEntityAdapters.prices.upsertOne(state.prices, { itemId: action.meta.arg, prices: action.payload });
      })
      .addCase(fetchCatalogThunk.fulfilled, (state, action) => {
        if (action.payload) {
          catalogEntityAdapters.items.upsertMany(state.items, action.payload.items);
          const key = createCatalogFilterKey(action.meta.arg);

          state.pageHash = {
            ...state.pageHash,
            [key]: {
              ...(state.pageHash[key] ?? {}),
              pages: {
                ...(state.pageHash[key]?.pages ?? {}),
                [action.meta.arg.page]: action.payload.items.map(x => x.itemId)
              },
              totalItems: action.payload.totalItems
            }
          };
        }
      })
      .addCase(fetchSubPartsThunk.fulfilled, (state, action) => {
        catalogEntityAdapters.items.upsertMany(state.items, action.payload);

        state.subPartHash = {
          ...state.subPartHash,
          [action.meta.arg]: action.payload.map<{ itemId: string; qtyRequired: number }>(x => ({
            itemId: x.itemId,
            qtyRequired: x.qtyRequired
          }))
        };
      })
      .addCase(fetchItemThunk.fulfilled, (state, action) => {
        catalogEntityAdapters.items.upsertMany(state.items, action.payload);

        const prices = action.payload
          .map(x => ({ itemId: x.itemId, prices: x.prices ?? [] }))
          .filter(x => x.prices.length > 0);

        if (prices.length > 0) {
          catalogEntityAdapters.prices.upsertMany(state.prices, prices);
        }
      })
      .addCase(fetchProductColorsThunk.fulfilled, (state, action) => {
        if (action.payload) {
          catalogEntityAdapters.colors.upsertMany(state.colors, action.payload.colors);

          const existingItem = state.items.entities[action.payload.itemId];

          if (existingItem) {
            catalogEntityAdapters.items.upsertOne(state.items, {
              ...existingItem,
              colors: action.payload.colors.map(x => x.inventColorId)
            });
          }
        }
      })
      .addCase(fetchCatalogSectionThunk.fulfilled, (state, action) => {
        if (action.payload) {
          const processed = processProducts(action.payload.items);

          catalogEntityAdapters.items.upsertMany(state.items, processed.products);
          catalogEntityAdapters.colors.upsertMany(state.colors, processed.colors);
        }
      })
      .addCase(loginThunk.rejected, () => initialState)
      .addCase(fetchCurrentAccessTokenThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.pending, () => initialState);
  }
});

export const { actions: catalogActionCreators, reducer: catalogReducer } = catalogSlice;
