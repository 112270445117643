import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../..';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { coreActions, getIsEmployee } from '../../core';
import { IUpdateCartLineResult } from '../cartsInterfaces';

export const addConfigurationToCartThunk = createAsyncThunk(
  'carts/addConfiguration',
  async (
    { configurationNumber, cartId, quantity = 1 }: { configurationNumber: string; cartId?: number; quantity?: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    cartId = cartId ?? state.carts.activeCartId;
    const isEmployee = getIsEmployee(state);

    dispatch(coreActions.toggleWorkingOnItDialog());
    const result = await executeApiRequest<IUpdateCartLineResult | { isCartValid: boolean }>(() =>
      httpService.post('/Cart/AddConfiguration', { cartId, configurationNumber, quantity })
    );
    dispatch(coreActions.toggleWorkingOnItDialog());

    if (result.requestFailed) {
      toast(`Failed to add configuration ${configurationNumber} to cart.  Please try again.`, { type: 'error' });
      return rejectWithValue(`Failed to add configuration ${configurationNumber} to cart.`);
    }

    if (result.value) {
      if (isUpdateCartLineResult(result.value)) {
        if (result.value.errors.length > 0) {
          if (isEmployee) {
            // TODO implment this dialog
            //yield put(modalActions.showModal('PROCESS_CARTLINE_ERRORS', { updateErrors: value.errors }));
          } else {
            const message =
              `Your configuration contains non-standard parts, but don't worry, it has been saved.` +
              `  Please contact the Customer Care Team at 877-998-2314 for help adding this configuration to your cart.`;

            dispatch(coreActions.toggleMessageDialog(message));
          }

          toast(`Added ${result.value.cartLines.length} items to cart with ${result.value.errors.length} warnings.`, {
            type: 'warning'
          });
        } else {
          toast(`Added ${result.value.cartLines.length} items to cart.`, { type: 'success' });
        }

        return result.value;
      } else {
        dispatch(
          coreActions.toggleMessageDialog(
            `This configuration was unable to be validated. Please open the configuration to view and fix the problems.`
          )
        );
      }
    }
  }
);

function isUpdateCartLineResult(obj: unknown): obj is IUpdateCartLineResult {
  return (obj as IUpdateCartLineResult)?.cartId !== undefined;
}
