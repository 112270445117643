import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { IUpdateTargetObj, IUpdateTargetResponse } from '..';
import { httpService } from '../../..';
import { executeApiRequest, IEditObject } from '../../../utils/asyncUtils';
import { coreActions } from '../../core';

export const updateTargetLevelThunk = createAsyncThunk(
  'preSeason/updateTargetLevel',
  async (update: IUpdateTargetObj, { rejectWithValue, dispatch }) => {
    dispatch(coreActions.toggleWorkingOnItDialog());
    const result = await executeApiRequest<IEditObject<IUpdateTargetResponse>>(() =>
      httpService.post(`/Preseason/UpdateTarget`, update)
    );
    dispatch(coreActions.toggleWorkingOnItDialog());

    if (result.requestFailed) {
      toast('Updating target level failed.  Please retry.', { type: 'error' });
      return rejectWithValue('Updating target level failed.');
    }

    toast('Target Level Updated', { type: 'success' });

    return result.value;
  }
);
