import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { coreActions } from '../../core';
import { IActiveCart } from '../cartsInterfaces';

export const setActiveCartThunk = createAsyncThunk(
  'carts/setActiveCart',
  async (cartId: number, { rejectWithValue, dispatch }) => {
    dispatch(coreActions.toggleWorkingOnItDialog());
    const response = await executeApiRequest<IActiveCart>(() => httpService.post(`/Cart/ActiveCart/${cartId}`));
    dispatch(coreActions.toggleWorkingOnItDialog());

    if (response.requestFailed) {
      return rejectWithValue('Failed to set Active Cart');
    }

    if (response.value?.notificationString) {
      dispatch(coreActions.toggleActiveCartMessageDialog(response.value.notificationString));
    }

    return response.value;
  }
);
