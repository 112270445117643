import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { keyBy, omit, uniq } from 'lodash-es';
import { fetchCurrentAccessTokenThunk, loginThunk, switchDealerThunk } from '../auth';
import { initAppThunk } from '../core';
import {
  initPreSeasonApprovalThunk,
  initPreSeasonThunk,
  initSubmitPreSeasonThunk,
  recalcPreSeasonOrderThunk,
  rejectPreSeasonOrderThunk,
  submitPreSeasonToAxThunk,
  updatePreSeasonItemThunk,
  updateTargetLevelThunk
} from '../preSeason';
import { CartStatus } from './cartEnums';
import {
  IActiveCart,
  IActiveCartCart,
  ICart,
  ICartLine,
  ICartNormalized,
  IUpdateCartLineResult
} from './cartsInterfaces';
import {
  addConfigurationToCartThunk,
  bulkAddItemsToCartThunk,
  bulkUpdateCartThunk,
  deleteCartLinesThunk,
  deleteCartLineThunk,
  deleteCartThunk,
  fetchActiveCartThunk,
  fetchSavedCartsThunk,
  setActiveCartThunk,
  splitLineThunk,
  updateCartLineThunk
} from './thunks';

interface ICartsState {
  activeCartId: number | undefined;
  carts: Record<number, ICartNormalized | undefined>;
  cartLines: Record<number, ICartLine | undefined>;
  activeCartLineIds: number[];
  activeItems: string[];
}

const initialState: ICartsState = {
  activeCartId: undefined,
  activeCartLineIds: [],
  activeItems: [],
  cartLines: {},
  carts: {}
};

const CartLIneRealtimeUpdate = createAction<IUpdateCartLineResult>('carts/realtime/cartLinesUpdate');

const cartsSlice = createSlice({
  initialState,
  name: 'carts',
  reducers: {},
  // eslint-disable-next-line sort-keys
  extraReducers: builder => {
    const normalizeCarts = (
      state: ICartsState,
      carts: Array<ICart | IActiveCartCart>
    ): { carts: Record<number, ICartNormalized>; cartLines: Record<number, ICartLine> } => {
      const normalizedCarts = carts.map<ICartNormalized>(x => {
        const current = state.carts[x.cartId] ?? { cartLineIds: [] };

        const updated = omit(
          {
            ...current,
            ...x,
            cartLineIds: x.cartLines ? x.cartLines.map(y => y.cartLineId) : current.cartLineIds
          },
          'cartLines'
        );

        return updated;
      });

      const cartHash = keyBy(normalizedCarts, x => x.cartId);
      const cartLines = keyBy(
        carts.flatMap(x => x.cartLines ?? []),
        x => x.cartLineId
      );

      return { cartLines, carts: cartHash };
    };

    const processUpdates = (
      state: ICartsState,
      action: PayloadAction<IUpdateCartLineResult | undefined>
    ): ICartsState => {
      if (!action.payload) {
        return state;
      }
      const update = action.payload;
      const currentCart = state.carts[update.cartId];

      const updatedCart: ICartNormalized | undefined = currentCart
        ? {
            ...currentCart,
            addressId: action.payload.addressId ?? currentCart?.addressId,
            axContactId: action.payload.contactId ?? currentCart.axContactId,
            cartLineIds: uniq([
              ...(currentCart.cartLineIds
                ? currentCart.cartLineIds.filter(x => !update.removedLineIds.includes(x))
                : []),
              ...update.cartLines.map(x => x.cartLineId)
            ]),
            description: action.payload.description ?? currentCart.description,
            freightCharge: action.payload.freightCharge ?? currentCart.freightCharge,
            modifiedAt: update.modifiedAt,
            modifiedByName: update.modifiedBy,
            requestedShipDate: action.payload.requestedShipDate ?? currentCart.requestedShipDate,
            shipMethod: action.payload.shipMethod ?? currentCart.shipMethod,
            shipToName: action.payload.addressId ? action.payload.shipToName ?? '' : currentCart.shipToName,
            termsChecked: action.payload.termsChecked ?? currentCart.termsChecked
          }
        : undefined;

      const newState: ICartsState = {
        ...state,
        activeCartLineIds: state.activeCartLineIds.filter(
          x => !action.payload?.cartLines.map(y => y.cartLineId).includes(x)
        ),
        activeItems: state.activeItems.filter(
          x => !action.payload?.cartLines.map(y => `${y.itemId}_${y.inventColorId}`).includes(x)
        ),
        cartLines: { ...omit(state.cartLines, update.removedLineIds), ...keyBy(update.cartLines, x => x.cartLineId) },
        carts: { ...omit(state.carts, update.cartId), [update.cartId]: updatedCart }
      };

      return newState;
    };

    const setActiveCart = (state: ICartsState, action: PayloadAction<IActiveCart | undefined>): ICartsState => {
      const newState = { ...state };
      if (action.payload) {
        newState.activeCartId = action.payload.activeCart?.cartId;

        if (action.payload.activeCart?.cart) {
          const normalized = normalizeCarts(newState, [action.payload.activeCart.cart]);
          newState.cartLines = { ...newState.cartLines, ...normalized.cartLines };
          newState.carts = { ...newState.carts, ...normalized.carts };
        }
      }

      return newState;
    };

    builder
      .addCase(CartLIneRealtimeUpdate, processUpdates)
      .addCase(deleteCartThunk.fulfilled, (state, action) => {
        state.carts = omit(state.carts, action.payload);
      })
      .addCase(fetchSavedCartsThunk.fulfilled, (state, action) => {
        if (action.payload && action.payload.length > 0) {
          const normalized = normalizeCarts(state, action.payload);
          state.cartLines = { ...state.cartLines, ...normalized.cartLines };
          state.carts = { ...state.carts, ...normalized.carts };
        }
      })
      .addCase(
        initAppThunk.fulfilled,
        (
          state,
          {
            payload: {
              activeCart: { activeCart }
            }
          }
        ) => {
          state.activeCartId = activeCart?.cartId;

          if (activeCart?.cart) {
            const normalized = normalizeCarts(state, [activeCart.cart]);
            state.cartLines = { ...state.cartLines, ...normalized.cartLines };
            state.carts = { ...state.carts, ...normalized.carts };
          }
        }
      )
      .addCase(initPreSeasonThunk.fulfilled, (state, action) => {
        if (action.payload) {
          const normalized = normalizeCarts(state, [action.payload.psCart.cart]);
          state.cartLines = { ...state.cartLines, ...normalized.cartLines };
          state.carts = { ...state.carts, ...normalized.carts };
        }
      })
      .addCase(initSubmitPreSeasonThunk.fulfilled, (state, action) => {
        if (action.payload) {
          const carts = action.payload.psCarts.map(x => x.cart);
          const normalized = normalizeCarts(state, carts);

          state.cartLines = { ...state.cartLines, ...normalized.cartLines };
          state.carts = { ...state.carts, ...normalized.carts };
        }
      })
      .addCase(initPreSeasonApprovalThunk.fulfilled, (state, action) => {
        if (action.payload) {
          const carts = action.payload.psCarts.map(x => x.cart);
          const normalized = normalizeCarts(state, carts);

          state.cartLines = { ...state.cartLines, ...normalized.cartLines };
          state.carts = { ...state.carts, ...normalized.carts };
        }
      })
      .addCase(updateTargetLevelThunk.fulfilled, (state, action) => {
        if (action.payload?.value.cartLines) {
          if (action.payload.value.cartLines.length > 0) {
            const cart = state.carts[action.payload.value.cartLines[0].cartId];

            if (cart) {
              state.carts = {
                ...state.carts,
                [cart.cartId]: { ...cart, modifiedAt: action.payload.editedAt, modifiedByName: action.payload.editedBy }
              };
            }

            state.cartLines = { ...state.cartLines, ...keyBy(action.payload.value.cartLines, x => x.cartLineId) };
          }
        }
      })
      .addCase(updateCartLineThunk.pending, (state, action) => {
        if (action.meta.arg.cartLine.cartLineId > 0) {
          state.activeCartLineIds = [...state.activeCartLineIds, action.meta.arg.cartLine.cartLineId];
        } else {
          state.activeItems = [
            ...state.activeItems,
            `${action.meta.arg.cartLine.itemId}_${action.meta.arg.cartLine.inventColorId}`
          ];
        }
      })
      .addCase(updatePreSeasonItemThunk.pending, (state, action) => {
        state.activeItems = [...state.activeItems, `${action.meta.arg.itemId}_${action.meta.arg.inventColorId}`];
      })
      .addCase(updatePreSeasonItemThunk.rejected, (state, action) => {
        state.activeItems = state.activeItems.filter(
          x => x !== `${action.meta.arg.itemId}_${action.meta.arg.inventColorId}`
        );
      })
      .addCase(submitPreSeasonToAxThunk.fulfilled, (state, action) => {
        if (action.payload) {
          const cart = state.carts[action.meta.arg.cart.cartId];

          if (cart) {
            state.carts[cart.cartId] = {
              ...cart,
              cartStatusId: CartStatus.Submitted,
              salesOrders: action.payload.salesIds.map<{ axSalesId: string; cartId: number }>(x => ({
                axSalesId: x,
                cartId: cart.cartId
              })),
              submittedAt: action.payload.processedAt
            };
          }
        }
      })
      .addCase(rejectPreSeasonOrderThunk.fulfilled, (state, action) => {
        if (action.payload?.cart) {
          const normalized = normalizeCarts(state, [action.payload.cart]);
          state.cartLines = { ...state.cartLines, ...normalized.cartLines };
          state.carts = { ...state.carts, ...normalized.carts };
        }
      })
      .addCase(recalcPreSeasonOrderThunk.fulfilled, (state, action) => {
        if (action.payload?.cart) {
          const normalized = normalizeCarts(state, [action.payload.cart]);
          state.cartLines = { ...state.cartLines, ...normalized.cartLines };
          state.carts = { ...state.carts, ...normalized.carts };
        }
      })
      .addCase(setActiveCartThunk.fulfilled, setActiveCart)
      .addCase(fetchActiveCartThunk.fulfilled, setActiveCart)
      .addCase(splitLineThunk.fulfilled, processUpdates)
      .addCase(updatePreSeasonItemThunk.fulfilled, processUpdates)
      .addCase(updateCartLineThunk.fulfilled, processUpdates)
      .addCase(deleteCartLineThunk.fulfilled, processUpdates)
      .addCase(deleteCartLinesThunk.fulfilled, processUpdates)
      .addCase(bulkUpdateCartThunk.fulfilled, processUpdates)
      .addCase(addConfigurationToCartThunk.fulfilled, processUpdates)
      .addCase(bulkAddItemsToCartThunk.fulfilled, processUpdates)
      .addCase(loginThunk.rejected, () => initialState)
      .addCase(fetchCurrentAccessTokenThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.pending, () => initialState);
  }
});

export const { actions: cartsActions, reducer: cartsReducer } = cartsSlice;
