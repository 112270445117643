import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faGoogle, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { AuthProviders } from '../utils/auth/authInterfaces';
import buttonStyle from './SocialButtons.module.scss';

interface ILoginButtonProps {
  provider: AuthProviders;
  onClick: (variant: AuthProviders) => void;
}

const labels: Record<AuthProviders, { label: string; icon?: IconDefinition }> = {
  adfs: { label: 'Employee Login' },
  //facebook: { icon: faFacebookF, label: 'Facebook' },
  google: { icon: faGoogle, label: 'Google' },
  twitter: { icon: faTwitter, label: 'Twitter' }
};

let LoginButton: React.ComponentType<ILoginButtonProps> = ({ provider, onClick }) => {
  const handleClick = React.useCallback(() => {
    onClick(provider);
  }, [provider, onClick]);
  const item = React.useMemo(() => labels[provider], [provider]);

  return (
    <button
      type="button"
      className={`btn btn-block btn-social ${buttonStyle[`btn-${provider}`]}`}
      onClick={handleClick}
    >
      {item.icon && <FontAwesomeIcon icon={item.icon} />}
      {` ${item.label}`}
    </button>
  );
};

LoginButton = React.memo(LoginButton);
export { LoginButton };
