import { Realtime } from 'ably';
import { useEffect } from 'react';
import { store } from '../';
import { useAppSelector } from './redux';

const client = new Realtime({ key: process.env.REACT_APP_REALTIME_KEY });
/**
 * Uses realtime service to watch messages and send them to redux
 */
export function useRealtime(channelName: string | string[]) {
  const accountNum = useAppSelector(state => state.core.me?.accountNum);

  useEffect(() => {
    console.info('UPDATING REALTIME', channelName);
    const channelNames = Array.isArray(channelName) ? channelName : [channelName];
    const channels = channelNames.map(name => client.channels.get(name));

    //const channel = clientRef.current.channels.get(channelName);

    channels.forEach(channel => {
      channel.subscribe(message => {
        if (message.data && message.data.type) {
          store.dispatch({ ...message.data, payload: { ...message.data.payload, accountNum } });
        }
      });
    });

    return () => {
      channels.forEach(channel => {
        channel.unsubscribe();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelCompareFunc(channelName)]);
}

const channelCompareFunc = (channelName: string | string[]): string => {
  if (Array.isArray(channelName)) {
    return [...channelName].sort().join(',');
  }
  return channelName;
};
