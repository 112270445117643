import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { IProductColor } from '../catalogInterfaces';

export const fetchProductColorsThunk = createAsyncThunk(
  'catalog/fetchColors',
  async (itemId: string, { rejectWithValue }) => {
    const results = await executeApiRequest<IProductColor[]>(() => httpService.get(`/Products/${itemId}/Colors`));

    if (results.requestFailed) {
      return rejectWithValue(`Failed to fetch colors for item ${itemId}`);
    }

    return results.value ? { colors: results.value, itemId } : undefined;
  }
);
