import { createSlice } from '@reduxjs/toolkit';
import { fetchCurrentAccessTokenThunk, loginThunk, switchDealerThunk } from '../auth';
import { updateNewsFeedPostAction } from './legacyActions/updateNewsFeedPost';
import { newsFeedEntityAdapters } from './newsFeedEntityAdapters';
import { fetchNewsFeedPostsThunk, fetchNewsFeedPostThunk } from './thunks';

const initialState = {
  posts: newsFeedEntityAdapters.posts.getInitialState()
};

const newsFeedSlice = createSlice({
  initialState,
  name: 'newsFeed',
  reducers: {},
  // eslint-disable-next-line sort-keys
  extraReducers: builder => {
    builder
      .addCase(fetchNewsFeedPostThunk.fulfilled, (state, action) => {
        if (action.payload) {
          newsFeedEntityAdapters.posts.upsertOne(state.posts, action.payload);
        }
      })
      .addCase(fetchNewsFeedPostsThunk.fulfilled, (state, action) => {
        newsFeedEntityAdapters.posts.upsertMany(state.posts, action.payload);
      })
      .addCase(updateNewsFeedPostAction, (state, action) => {
        if (action.payload.access.includes(action.payload.accountNum)) {
          newsFeedEntityAdapters.posts.upsertOne(state.posts, action.payload.post);
        } else {
          newsFeedEntityAdapters.posts.removeOne(state.posts, action.payload.post.dpNewsFeedPostId);
        }
      })
      .addCase(loginThunk.rejected, () => initialState)
      .addCase(fetchCurrentAccessTokenThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.pending, () => initialState);
  }
});

export const { actions: newsFeedActions, reducer: newsFeedReducer } = newsFeedSlice;
