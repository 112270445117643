import { useCallback, useState } from 'react';

/** A hook to simplify using form values */
export function useForm<T>(value: T): [T, (field: keyof T) => (value: unknown) => void, boolean, (value: T) => void] {
  const [val, setVal] = useState<T>(value);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const handleUpdate = useCallback(
    // TODO: better typing for second parameter (get rid of any)
    (field: keyof T) => (newVal: unknown) => {
      setVal(current => ({ ...current, [field]: newVal }));
      setHasChanges(true);
    },
    []
  );

  return [val, handleUpdate, hasChanges, setVal];
}
