import { sendError } from '../utils/logging';

export interface IAsyncResponse<T> {
  value?: T;
  requestFailed: boolean;
  exception?: Error;
  statusCode?: number;
}

export interface IPagedResponse<T> {
  items: T[];
  totalItems: number;
}

export async function executeApiRequest<T>(
  apiFunc: () => Promise<Response>,
  parseJson: boolean = true
): Promise<IAsyncResponse<T>> {
  const response: IAsyncResponse<T> = { requestFailed: false };

  try {
    const apiReponse = await apiFunc();
    response.statusCode = apiReponse.status;
    response.requestFailed = !apiReponse.ok;

    if (parseJson) {
      response.value = await apiReponse.json();
    }
  } catch (e) {
    sendError(e);
    response.exception = e;
    response.requestFailed = true;
  }

  return response;
}

export interface IEditObject<T> {
  editedAt: string;
  editedBy: string;
  value: T;
}
