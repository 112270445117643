import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { ICart } from '../cartsInterfaces';

export const fetchSavedCartsThunk = createAsyncThunk('carts/savedCarts', async (_, { rejectWithValue }) => {
  const response = await executeApiRequest<ICart[]>(() => httpService.get('/Cart'));

  if (response.requestFailed) {
    return rejectWithValue('Failed to fetch saved carts');
  }

  return response.value;
});
