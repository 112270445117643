import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { fetchCurrentAccessTokenThunk, loginThunk, switchDealerThunk } from '../auth';
import { IProfile, IProfileContact } from './profileInterfaces';
import { fetchProfileThunk, updateContactThunk, updateProfileThunk } from './thunks';

export const contactsEntityAdapter = createEntityAdapter<IProfileContact>({
  selectId: x => x.contactId,
  sortComparer: (a, b) => `${a.lastName}, ${a.firstName}`.localeCompare(`${b.lastName}, ${b.firstName}`)
});
interface IProfileState {
  contacts: EntityState<IProfileContact>;
  profile: IProfile | undefined;
  titles: string[];
}

const initialState: IProfileState = {
  contacts: contactsEntityAdapter.getInitialState(),
  profile: undefined,
  titles: []
};

const profileSlice = createSlice({
  initialState,
  name: 'profile',
  reducers: {},
  // eslint-disable-next-line sort-keys
  extraReducers: builder => {
    builder
      .addCase(fetchProfileThunk.fulfilled, (state, action) => {
        state.profile = action.payload?.profile;
        contactsEntityAdapter.upsertMany(state.contacts, action.payload?.profile.contacts ?? []);
        state.titles = action.payload?.titles ?? [];
      })
      .addCase(updateProfileThunk.fulfilled, (state, action) => {
        if (action.payload?.profile) {
          state.profile = action.payload.profile;
          contactsEntityAdapter.upsertMany(state.contacts, action.payload?.profile.contacts ?? []);
        }
      })
      .addCase(updateContactThunk.fulfilled, (state, action) => {
        if (action.payload) {
          contactsEntityAdapter.upsertOne(state.contacts, action.payload);
        }
      })
      .addCase(loginThunk.rejected, () => initialState)
      .addCase(fetchCurrentAccessTokenThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.pending, () => initialState);
  }
});

export const { reducer: profileReducer } = profileSlice;
