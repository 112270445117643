interface IDateRange {
  startDate: Date;
  endDate: Date;
}

const fiscalRange = {
  endMonth: 7,
  startMonth: 8
};

export const getFiscalYearRange = (year: string): IDateRange => {
  const yearNum: number = parseInt(year, 10);

  const startDate = new Date(yearNum - 1, 8, 1);
  const endDate = new Date(yearNum, 7, 31);

  return { endDate, startDate };
};

export function getFiscalYear(date?: Date | string): string {
  date = date ? new Date(date) : new Date();
  if (date.getMonth() >= fiscalRange.startMonth) {
    return (date.getFullYear() + 1).toString();
  }
  return date.getFullYear().toString();
}
