import { createAction, createSlice, EntityState } from '@reduxjs/toolkit';
import { switchDealerThunk } from '../auth';
import { IPaymentTerm } from '../core';
import { preSeasonEntityAdapters } from './preSeasonEntityAdapters';
import {
  IPaymentTermRange,
  IPreSeasonApproval,
  IPreSeasonApprovalType,
  IPreSeasonCart,
  IPreSeasonCartNormalized,
  IPreseasonCatalogItemGroup,
  IPreseasonCatalogSection,
  IPreseasonWeekOption,
  IPreseasonWorksheetResult,
  IProgramLevel
} from './preSeasonInterfaces';
import {
  editPreSeasonOrderThunk,
  fetchCatalogSectionThunk,
  initPreSeasonApprovalThunk,
  initPreSeasonThunk,
  initSubmitPreSeasonThunk,
  recalcPreSeasonOrderThunk,
  rejectPreSeasonOrderThunk,
  submitPreSeasonOrderThunk,
  submitPreSeasonWorksheetThunk,
  updatePreSeasonApprovalThunk,
  updateTargetLevelThunk
} from './thunks';

interface IPreSeasonState {
  activeCartBrand: string | undefined;
  approvals: IPreSeasonApproval[];
  approvalTypes: EntityState<IPreSeasonApprovalType>;
  availableCatalogSections: Record<string, number[] | undefined>;
  availableLevels: Record<string, number[] | undefined>;
  catalogItemGroups: Record<number, IPreseasonCatalogItemGroup[] | undefined>;
  catalogSections: EntityState<IPreseasonCatalogSection>;
  currentBrand: string;
  floorPlanHash: Record<string, boolean>;
  isApprovalUser: boolean;
  paymentTerms: IPaymentTerm[];
  paymentTermShipRanges: IPaymentTermRange[];
  psCarts: EntityState<IPreSeasonCartNormalized>;
  programLevels: EntityState<IProgramLevel>;
  salesHistory: Record<string, { inSeason: number; preseason: number } | undefined>;
  shippingWeeks: IPreseasonWeekOption[];
  worksheets: Record<string, IPreseasonWorksheetResult | undefined>;
}

const initialState: IPreSeasonState = {
  activeCartBrand: undefined,
  approvalTypes: preSeasonEntityAdapters.approvalTypes.getInitialState(),
  approvals: [],
  availableCatalogSections: {},
  availableLevels: {},
  catalogItemGroups: {},
  catalogSections: preSeasonEntityAdapters.catalogSections.getInitialState(),
  currentBrand: '',
  floorPlanHash: {},
  isApprovalUser: false,
  paymentTermShipRanges: [],
  paymentTerms: [],
  programLevels: preSeasonEntityAdapters.programLevels.getInitialState(),
  psCarts: preSeasonEntityAdapters.psCarts.getInitialState(),
  salesHistory: {},
  shippingWeeks: [],
  worksheets: {}
};

const PreSeasonApprovalRealtimeUpdate = createAction<IPreSeasonApproval>('preSeason/realtime/psApprovalUpdate');
const PreSeasonCartRealtimeUpdate = createAction<IPreSeasonCart>('preSeason/realtime/psCartUpdate');

const slice = createSlice({
  initialState,
  name: 'preseason',
  reducers: {},
  // eslint-disable-next-line sort-keys
  extraReducers: builder => {
    const noramlizePsCart = (psCart: IPreSeasonCart): IPreSeasonCartNormalized => ({
      accountNum: psCart.accountNum,
      brand: psCart.brand,
      cartId: psCart.cartId,
      fiscalYear: psCart.fiscalYear,
      imported: psCart.imported,
      preseasonGoalTypeId: psCart.preseasonGoalTypeId,
      submitted: psCart.submitted,
      submittedAtUtc: psCart.submittedAtUtc,
      submittedByEmail: psCart.submittedByEmail,
      submittedByName: psCart.submittedByName,
      targetProgramLevelId: psCart.targetProgramLevelId
    });

    builder
      .addCase(initPreSeasonThunk.fulfilled, (state, action) => {
        if (action.payload) {
          preSeasonEntityAdapters.psCarts.upsertMany(state.psCarts, [noramlizePsCart(action.payload.psCart)]);
          preSeasonEntityAdapters.catalogSections.upsertMany(state.catalogSections, action.payload.catalogSections);
          state.activeCartBrand = action.payload.psCart.brand;
          state.availableLevels = {
            ...state.availableLevels,
            [action.payload.psCart.brand]: action.payload.levels.map(x => x.programLevelId)
          };
          state.availableCatalogSections = {
            ...state.availableCatalogSections,
            [action.payload.psCart.brand]: action.payload.catalogSections.map(x => x.preseasonCatalogSectionId)
          };

          state.worksheets = { ...state.worksheets, [action.payload.psCart.brand]: action.payload.worksheetResult };
          preSeasonEntityAdapters.programLevels.upsertMany(state.programLevels, action.payload.levels);
          state.salesHistory = action.payload.salesHash;
        }
      })
      .addCase(initSubmitPreSeasonThunk.fulfilled, (state, action) => {
        if (action.payload) {
          preSeasonEntityAdapters.psCarts.upsertMany(state.psCarts, action.payload.psCarts.map(noramlizePsCart));
          preSeasonEntityAdapters.programLevels.upsertMany(state.programLevels, action.payload.programLevels);
          state.paymentTerms = action.payload.paymentTerms;
          state.floorPlanHash = action.payload.floorPlanHash;
          state.paymentTermShipRanges = action.payload.paymentTermShipRanges;
          state.shippingWeeks = action.payload.weeks;
        }
      })
      .addCase(submitPreSeasonWorksheetThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.worksheets = { ...state.worksheets, [action.meta.arg.brand]: action.payload.value };
        }
      })
      .addCase(fetchCatalogSectionThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.catalogItemGroups = { ...state.catalogItemGroups, [action.meta.arg]: action.payload.groups };
        }
      })
      .addCase(updateTargetLevelThunk.fulfilled, (state, action) => {
        if (action.payload) {
          const applicableCart = Object.values(state.psCarts.entities).find(
            x => x?.brand === action.meta.arg.brand && x.fiscalYear === action.meta.arg.year
          );

          if (applicableCart) {
            applicableCart.targetProgramLevelId = action.payload.value.programTargetLevelId;
            applicableCart.preseasonGoalTypeId = action.payload.value.preseasonGoalTypeId;
          }
        }
      })
      .addCase(submitPreSeasonOrderThunk.fulfilled, (state, action) => {
        if (action.payload) {
          const cart = state.psCarts.entities[action.payload.value.brand];

          if (cart) {
            preSeasonEntityAdapters.psCarts.upsertOne(state.psCarts, {
              ...cart,
              submitted: true,
              submittedAtUtc: action.payload.value.submittedAtUtc,
              submittedByName: action.payload.value.submittedBy
            });
          }
        }
      })
      .addCase(editPreSeasonOrderThunk.fulfilled, (state, action) => {
        if (action.payload) {
          const cart = state.psCarts.entities[action.payload.value];

          if (cart) {
            preSeasonEntityAdapters.psCarts.upsertOne(state.psCarts, {
              ...cart,
              submitted: false,
              submittedAtUtc: '',
              submittedByName: ''
            });
          }
        }
      })
      .addCase(initPreSeasonApprovalThunk.fulfilled, (state, action) => {
        if (action.payload) {
          preSeasonEntityAdapters.approvalTypes.upsertMany(state.approvalTypes, action.payload.approvalTypes);
          preSeasonEntityAdapters.psCarts.upsertMany(state.psCarts, action.payload.psCarts.map(noramlizePsCart));
          preSeasonEntityAdapters.programLevels.upsertMany(state.programLevels, action.payload.levels);

          state.isApprovalUser = action.payload.isApprovalUser;
          state.approvals = action.payload.approvals;
        }
      })
      .addCase(PreSeasonApprovalRealtimeUpdate, (state, action) => {
        const newApproval = action.payload;
        state.approvals = [
          ...state.approvals.filter(
            x => `${x.cartId}_${x.approvalTypeId}` !== `${newApproval.cartId}_${newApproval.approvalTypeId}`
          ),
          action.payload
        ];
      })
      .addCase(updatePreSeasonApprovalThunk.fulfilled, (state, action) => {
        if (action.payload) {
          const newApproval = action.payload;

          state.approvals = [
            ...state.approvals.filter(
              x => `${x.cartId}_${x.approvalTypeId}` !== `${newApproval.cartId}_${newApproval.approvalTypeId}`
            ),
            action.payload
          ];
        }
      })
      .addCase(rejectPreSeasonOrderThunk.fulfilled, (state, action) => {
        if (action.payload) {
          preSeasonEntityAdapters.psCarts.upsertOne(state.psCarts, noramlizePsCart(action.payload));
        }
      })
      .addCase(recalcPreSeasonOrderThunk.fulfilled, (state, action) => {
        if (action.payload) {
          preSeasonEntityAdapters.psCarts.upsertOne(state.psCarts, noramlizePsCart(action.payload));
        }
      })
      .addCase(PreSeasonCartRealtimeUpdate, (state, action) => {
        preSeasonEntityAdapters.psCarts.upsertOne(state.psCarts, noramlizePsCart(action.payload));
      })
      .addCase(switchDealerThunk.pending, () => initialState);
  }
});

export const { reducer: preSeasonReducer } = slice;
