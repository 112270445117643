import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ICartLine, IUpdateCartLineResult } from '../';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { coreActions } from '../../core';

export const updateCartLineThunk = createAsyncThunk(
  'carts/updateCartLine',
  async (
    { cartLine, disableDialog }: { cartLine: ICartLine; disableDialog?: boolean },
    { rejectWithValue, dispatch }
  ) => {
    if (!disableDialog) {
      dispatch(coreActions.toggleWorkingOnItDialog());
    }
    const response = await executeApiRequest<IUpdateCartLineResult>(() => httpService.post('/CartLines', cartLine));
    if (!disableDialog) {
      dispatch(coreActions.toggleWorkingOnItDialog());
    }

    if (response.requestFailed) {
      toast('There was a problem adding this item to the cart. Please retry.', { type: 'error' });

      return rejectWithValue('Failed To Update cartline');
    }

    toast(
      `${cartLine.description ? cartLine.description : cartLine.itemId} ${
        cartLine.cartLineId > 0 ? 'updated' : 'added'
      }`,
      { type: 'success' }
    );

    return response.value;
  }
);
