import * as React from 'react';
import styled from 'styled-components';
import { Spinner } from '../components/Spinner';

interface ISplashPageProps {}

const Container = styled.div`
  align-items: center;
  background-color: ${props => props.theme.pallette.common.white};
  display: flex;
  height: 100vh;
  justify-content: center;
  div.content {
    max-width: 500px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

let SplashPage: React.ComponentType<ISplashPageProps> = () => {
  return (
    <Container>
      <div className="content">
        <img alt="" src="https://static.shoremaster.com/img/wfb_llc.png" className="img-responsive" />
        <Spinner />
      </div>
    </Container>
  );
};

SplashPage = React.memo(SplashPage);
export { SplashPage };
