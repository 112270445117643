import { createSelector } from '@reduxjs/toolkit';
import { isWithinInterval } from 'date-fns';
import orderBy from 'lodash-es/orderBy';
import { RootState } from '..';
import { brandAbbreviationToCssBackgroundClass } from '../../utils/brandAbbreviations';
import { notUndefined } from '../../utils/objectUtils';
import { getCart } from '../carts';
import { preSeasonEntityAdapters } from './preSeasonEntityAdapters';
import {
  IPreSeasonCartFull,
  IPreseasonCatalogItemGroupWithProducts,
  IPreseasonCatalogSection,
  IPreseasonWeekOption,
  IProductWithColor,
  IProgramLevel
} from './preSeasonInterfaces';

export const preSeasonCartSelectors = preSeasonEntityAdapters.psCarts.getSelectors(
  (state: RootState) => state.preSeason.psCarts
);
export const preSeasonCatalogSelectors = preSeasonEntityAdapters.catalogSections.getSelectors(
  (state: RootState) => state.preSeason.catalogSections
);
export const preSeasonProgramLevelSelectors = preSeasonEntityAdapters.programLevels.getSelectors(
  (state: RootState) => state.preSeason.programLevels
);
export const preSeasonAprovalTypeSelectors = preSeasonEntityAdapters.approvalTypes.getSelectors(
  (state: RootState) => state.preSeason.approvalTypes
);

export const getCartApprovals = createSelector(
  [(state: RootState) => state.preSeason.approvals, (_: RootState, cartId: number) => cartId],
  (approvals, cartId) => approvals.filter(x => x.cartId === cartId)
);

export const getActivePreSeasonCart = createSelector(
  [
    (state: RootState) => state.preSeason.activeCartBrand,
    (state: RootState) => state.preSeason.psCarts.entities,
    (state: RootState) => state.preSeason.programLevels.entities,
    (state: RootState) => state
  ],
  (activeCartBrand, psCartHash, levelHash, state): IPreSeasonCartFull | undefined => {
    if (activeCartBrand) {
      const psCart = psCartHash[activeCartBrand];

      if (psCart) {
        const cart = getCart(state, psCart.cartId);

        if (cart) {
          return {
            ...psCart,
            cart,
            level: psCart.targetProgramLevelId ? levelHash[psCart.targetProgramLevelId] : undefined
          };
        }
      }
    }
  }
);

export const getPreSeasonCarts = createSelector(
  [
    (state: RootState) => state.preSeason.psCarts.entities,
    (state: RootState) => state.preSeason.programLevels.entities,
    (state: RootState) => state
  ],
  (psCartHash, levelHash, state): IPreSeasonCartFull[] => {
    const psCarts = Object.values(psCartHash).filter(notUndefined);

    return psCarts.map<IPreSeasonCartFull>(x => ({
      ...x,
      cart: getCart(state, x.cartId),
      level: x.targetProgramLevelId ? levelHash[x.targetProgramLevelId] : undefined
    }));
  }
);

export const getAvailableProgramLevels = createSelector(
  [
    (state: RootState) => state.preSeason.activeCartBrand,
    (state: RootState) => state.preSeason.availableLevels,
    (state: RootState) => state.preSeason.programLevels.entities
  ],
  (brand, levelIds, levelHash): IProgramLevel[] => {
    if (brand) {
      const ids = levelIds[brand];
      if (ids) {
        return ids.map(x => levelHash[x]).filter(notUndefined);
      }
    }
    return [];
  }
);

export const getAvailableCatalogSections = createSelector(
  [
    (state: RootState) => state.preSeason.activeCartBrand,
    (state: RootState) => state.preSeason.availableCatalogSections,
    (state: RootState) => state.preSeason.catalogSections.entities
  ],
  (brand, catalogSectionIds, sectionHash): IPreseasonCatalogSection[] => {
    if (brand) {
      const ids = catalogSectionIds[brand];
      if (ids) {
        return ids.map(x => sectionHash[x]).filter(notUndefined);
      }
    }
    return [];
  }
);

export const getSalesHistory = createSelector(
  [
    (state: RootState) => state.preSeason.salesHistory,
    (_: RootState, values: { itemId: string; colorId: string }) => values
  ],
  (salesHash, { colorId, itemId }): { preseason: number; inSeason: number } => {
    const history = salesHash[`${itemId}_${colorId}`];

    return history ? history : { inSeason: 0, preseason: 0 };
  }
);

export const getCatalogSectionPrimaryBrandColorClass = createSelector(
  preSeasonCatalogSelectors.selectById,
  (section): string => (section?.brand ? brandAbbreviationToCssBackgroundClass(section.brand) : '')
);

export const getFlattenedPreseasonItemGroups = createSelector(
  [
    (state: RootState) => state.preSeason.catalogItemGroups,
    (state: RootState) => state.catalog.items.entities,
    (state: RootState) => state.catalog.colors.entities,
    (_: RootState, sectionId: number) => sectionId
  ],
  (itemGroupHash, itemHash, colors, sectionId): IPreseasonCatalogItemGroupWithProducts[] => {
    const itemGroups = itemGroupHash[sectionId] ? orderBy(itemGroupHash[sectionId], x => x.order) : [];

    return itemGroups.map<IPreseasonCatalogItemGroupWithProducts>(x => {
      const orderedProducts = orderBy(x.products, y => y.order);

      return {
        ...x,
        items: orderedProducts.reduce<IProductWithColor[]>((arr, i) => {
          const p = itemHash[i.itemId];
          let newItems: IProductWithColor[] = [];

          if (p) {
            newItems = p.colors
              ? p.colors.reduce<IProductWithColor[]>((arr, colorId) => {
                  const color = colors[colorId];

                  if (color) {
                    arr.push({ ...p, colorId: color.inventColorId, colorName: color.name });
                  }

                  return arr;
                }, [])
              : [{ ...p, colorId: '', colorName: '' }];
          }

          return [...arr, ...newItems];
        }, [])
      };
    });
  }
);

export const getPaymentTermShippingOptions = createSelector(
  [
    (state: RootState) => state.preSeason.shippingWeeks,
    (state: RootState) => state.preSeason.paymentTermShipRanges,
    (_: RootState, paymentTerm: string | undefined) => paymentTerm
  ],
  (shippingWeeks, paymentTermShipRanges, paymentTerm) => {
    if (paymentTerm) {
      const ranges = paymentTermShipRanges.reduce<Record<string, IPreseasonWeekOption[]>>(
        (paymTermMap, paymTermObj) => {
          const { endDate, startDate } = paymTermObj;
          const available =
            shippingWeeks.filter(x =>
              isWithinInterval(new Date(x.value), { end: new Date(endDate), start: new Date(startDate) })
            ) ?? [];
          paymTermMap[paymTermObj.paymentTermId] = available;

          return paymTermMap;
        },
        {}
      );

      return ranges[paymentTerm] ? ranges[paymentTerm] : [];
    }

    return [];
  }
);

// export function getFlattenedPreseasonItemGroups(
//   state: RootState,
//   sectionId: number
// ): IPreseasonCatalogItemGroupWithProducts[] {
//   const section = state.preseason.catalogSections.sections[sectionId];
//   const orderedGroups = section ? orderBy<IPreseasonCatalogItemGroup>(section.groups, x => x.order) : [];

//   return orderedGroups.map<IPreseasonCatalogItemGroupWithProducts>(x => {
//     const orderedProducts = sortBy<IPreseasonCatalogItem>(x.products, y => y.order);

//     return {
//       ...x,
//       items: orderedProducts.reduce<IProductWithColor[]>((arr, i) => {
//         const p = state.catalog.items[i.itemId];
//         let newItems: IProductWithColor[] = [];

//         if (p) {
//           newItems = p.hasColors
//             ? p.colors.map<IProductWithColor>(z => ({
//                 ...p,
//                 colorId: state.catalog.colors[z].inventColorId,
//                 colorName: state.catalog.colors[z].name
//               }))
//             : [{ ...p, colorId: '', colorName: '' }];
//         }

//         return [...arr, ...newItems];
//       }, [])
//     };
//   });
// }
