import * as React from 'react';
import styled from 'styled-components';

interface IColorLineProps {
  variant?: 'warning' | 'danger' | 'success';
}

const ColorLineDiv = styled.div<{ variant?: 'warning' | 'danger' | 'success' }>`
  background: ${({ variant, theme }) => (variant ? theme.pallette.common[variant] : '#f7f9fa')};
  height: 6px;
  background-image: ${({ variant, theme }) =>
    variant ? '' : `linear-gradient(to right, #545456 30%, ${theme.pallette.brandColors.llc} 100%)`};
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
`;

let ColorLine: React.ComponentType<IColorLineProps> = ({ variant }) => {
  return <ColorLineDiv variant={variant} />;
};

ColorLine = React.memo(ColorLine);
export { ColorLine };
