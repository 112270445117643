import { unwrapResult } from '@reduxjs/toolkit';
import * as React from 'react';
import { Alert } from 'react-bootstrap';
import styled from 'styled-components';
import { Spinner } from '../components/Spinner';
import { loginThunk, LoginThunkRejection } from '../ducks/auth';
import { useAppDispatch, useAppSelector, useDialog } from '../hooks';
import { AuthProviders } from '../utils/auth/authInterfaces';
import { LoginButton } from './LoginButton';
import { LoginErrorDialog } from './LoginErrorDialog';

interface ILoginButtonPanelProps {}

const Container = styled.div`
  max-width: 420px;
`;

let LoginButtonPanel: React.ComponentType<ILoginButtonPanelProps> = () => {
  const dispatch = useAppDispatch();
  const { isLoggingIn, loginError } = useAppSelector(state => state.auth);
  const [dialog, openDialog, closeDialog] = useDialog<'error', LoginThunkRejection>();

  const handleClick = React.useCallback(
    async (provider: AuthProviders): Promise<void> => {
      try {
        unwrapResult(await dispatch(loginThunk(provider)));
      } catch (e) {
        openDialog('error');
      }
    },
    [dispatch, openDialog]
  );

  return (
    <>
      <Container>
        <div>
          <div className="hpanel">
            <div className="panel-heading hbuilt">
              <img src="https://static.shoremaster.com/img/wfb_llc.png" className="img-responsive" alt="" />
              <h3 className="text-center text-uppercase">Dealer Portal Login</h3>
            </div>
            <div className="panel-body">
              {loginError && (
                <Alert bsStyle="warning" className="text-center">
                  Unable to automatically validate credentials. Please select an authentication provider below to
                  re-authenticate.
                </Alert>
              )}
              {!isLoggingIn && (
                <form action="#" id="loginForm">
                  {/* <LoginButton provider="facebook" onClick={handleClick} /> */}
                  <LoginButton provider="google" onClick={handleClick} />
                  {/* <LoginButton provider="twitter" onClick={handleClick} /> */}
                  <hr />
                  <LoginButton provider="adfs" onClick={handleClick} />
                </form>
              )}
              {isLoggingIn && (
                <div className="text-center">
                  <Spinner />
                  <h1 className="text-uppercase">Logging In</h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
      <LoginErrorDialog
        isOpen={dialog?.type === 'error'}
        onClose={closeDialog}
        email={dialog?.value.rejectedEmail}
        variant={dialog?.value.serverError ? 'serverError' : 'unauthorized'}
      />
    </>
  );
};

LoginButtonPanel = React.memo(LoginButtonPanel);
export { LoginButtonPanel };
