import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPreseasonSubmitResponse } from '..';
import { httpService } from '../../..';
import { executeApiRequest, IEditObject } from '../../../utils/asyncUtils';
import { coreActions } from '../../core';

export const submitPreSeasonOrderThunk = createAsyncThunk(
  'preSeason/submitOrder',
  async (cartId: number, { rejectWithValue, dispatch }) => {
    dispatch(coreActions.toggleWorkingOnItDialog());
    const response = await executeApiRequest<IEditObject<IPreseasonSubmitResponse>>(() =>
      httpService.post(`/Preseason/SubmitOrder`, cartId)
    );
    dispatch(coreActions.toggleWorkingOnItDialog());

    if (response.requestFailed) {
      return rejectWithValue(`Failed to submit pre-season order for cart ${cartId}`);
    }

    return response.value;
  }
);
