import * as React from 'react';
import { useDialog } from '../../hooks';
import { ButtonLink } from '../ButtonLink';
import { TermsAndConditionsDialog } from './TermsAndConditionsDialog';

interface ITermsAndConditionsLinkProps {}

let TermsAndConditionsLink: React.ComponentType<ITermsAndConditionsLinkProps> = () => {
  const [dialog, openDialog, closeDialog] = useDialog<'terms'>();

  return (
    <>
      <ButtonLink onClick={openDialog('terms')}>Terms And Conditions</ButtonLink>
      <TermsAndConditionsDialog isOpen={Boolean(dialog)} onClose={closeDialog} />
    </>
  );
};

TermsAndConditionsLink = React.memo(TermsAndConditionsLink);
export { TermsAndConditionsLink };
