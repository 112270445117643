import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { fetchCurrentAccessTokenThunk, loginThunk, switchDealerThunk } from '../auth';
import { IInventory } from './inventoryInterfaces';
import { fetchInventory } from './thunks';

interface IInventoryState {
  itemHash: Record<string, IInventory>;
  loading: string[];
}

const initialState: IInventoryState = {
  itemHash: {},
  loading: []
};

const inventorySlice = createSlice({
  initialState,
  name: 'inventory',
  reducers: {
    setInventory: (state, action: PayloadAction<IInventory[]>) => {
      state.itemHash = { ...state.itemHash, ...keyBy(action.payload, x => x.id) };
    }
  },
  // eslint-disable-next-line sort-keys
  extraReducers: builder => {
    builder
      .addCase(fetchInventory.pending, (state, action) => {
        state.loading = [...state.loading, action.meta.arg];
      })
      .addCase(fetchInventory.fulfilled, (state, action) => {
        if (action.payload.value) {
          state.itemHash = { ...state.itemHash, ...keyBy(action.payload.value, x => x.id) };
        }

        state.loading = state.loading.filter(x => x !== action.meta.arg);
      })
      .addCase(fetchInventory.rejected, (state, action) => {
        state.loading = state.loading.filter(x => x !== action.meta.arg);
      })
      .addCase(loginThunk.rejected, () => initialState)
      .addCase(fetchCurrentAccessTokenThunk.rejected, () => initialState)
      .addCase(switchDealerThunk.rejected, () => initialState);
  }
});

export const { actions: inventoryActionCreators, reducer: inventoryReducer } = inventorySlice;
