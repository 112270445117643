import { faBan, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface ILoginErrorUnauthorizedBodyProps {
  email?: string;
}

let LoginErrorUnauthorizedBody: React.ComponentType<ILoginErrorUnauthorizedBodyProps> = ({ email }) => {
  return (
    <>
      <span className="fa-layers fa-fw fa-5x">
        <FontAwesomeIcon icon={faUser} />
        <FontAwesomeIcon icon={faBan} className="text-danger" size="1x" />
      </span>
      <h3>
        {email ? (
          <>
            <strong>{email}</strong> is{' '}
          </>
        ) : (
          'You are'
        )}{' '}
        not authorized to access this resource.{' '}
      </h3>
      <h5>Please ensure the e-mail address is correct and try to login again.</h5>
    </>
  );
};

LoginErrorUnauthorizedBody = React.memo(LoginErrorUnauthorizedBody);
export { LoginErrorUnauthorizedBody };
