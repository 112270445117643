import * as React from 'react';
import { Alert } from 'react-bootstrap';
import style from './LogoutAlert.module.scss';

interface ILogoutAlertProps {
  showLogoutAlert: boolean;
}

let LogoutAlert: React.ComponentType<ILogoutAlertProps> = ({ showLogoutAlert }) => {
  return showLogoutAlert ? (
    <Alert bsStyle="warning" className={`${style.margin} text-center`}>
      {`You have succesfully logged out of the Waterfront Brands Dealer Portal, however you may still be logged into your
      authentication provider. To completely log out you will need to go to `}
      <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">
        Google
      </a>
      , or
      <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
        Twitter
      </a>{' '}
      and log out of their service.
    </Alert>
  ) : null;
};

LogoutAlert = React.memo(LogoutAlert);
export { LogoutAlert };
