import { unwrapResult } from '@reduxjs/toolkit';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { configRedux } from './configRedux';
import { fetchCurrentAccessTokenThunk, logoutThunk } from './ducks/auth';
import { Root } from './Root';
import * as serviceWorker from './serviceWorker';
import './styles/baseStyle.css';
import './styles/style.css';
import { styledComponentsTheme } from './styles/styledComponentsTheme';
import './styles/wfbStyles.scss';
import { AuthService } from './utils/auth/AuthService';
import { ErrorLoggerService } from './utils/ErrorLoggerService';
import { HttpService } from './utils/HttpService';

export const store = configRedux();
export const errorLogger = new ErrorLoggerService('BJhhUr/m4x0RnayywgD+JQ==', process.env.NODE_ENV);

export const httpService = new HttpService({
  accessTokenFunc: async (): Promise<string> => {
    const currentTokens = AuthService.getAuthTokens();

    if (
      currentTokens?.accessToken &&
      currentTokens?.decodedToken &&
      !AuthService.isAccessTokenExpired(currentTokens.decodedToken)
    ) {
      return currentTokens.accessToken;
    } else {
      const result = unwrapResult(await store.dispatch(fetchCurrentAccessTokenThunk()));
      return result?.accessToken ?? '';
    }
  },
  baseUrl: `${process.env.REACT_APP_SERVICE_URL}api`,
  errorLogFunc: errorLogger.sendError,
  logoutFunc: () => store.dispatch(logoutThunk())
});

const defaultTitle = 'Waterfront Brands, LLC Dealer Portal';
const titleTemplate = `%s | ${defaultTitle}`;

ReactDOM.render(
  <>
    <Helmet defaultTitle={defaultTitle} titleTemplate={titleTemplate} />
    <Provider store={store}>
      <ThemeProvider theme={styledComponentsTheme}>
        <Root />
      </ThemeProvider>
      <ToastContainer position="bottom-right" autoClose={5000} newestOnTop pauseOnHover transition={Slide} />
    </Provider>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
