import { createEntityAdapter } from '@reduxjs/toolkit';
import * as i from './lookupsInterfaces';

export const lookupEntityAdapters = {
  brands: createEntityAdapter<i.IBrand>({
    selectId: x => x.id,
    sortComparer: (a, b) => a.brand.localeCompare(b.brand)
  }),
  dpNewsFeedTags: createEntityAdapter<i.IDpNewsFeedTag>({
    selectId: x => x.dpNewsFeedTagId,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
  }),
  dpProductFamilies: createEntityAdapter<i.IDpProductFamily>({
    selectId: x => x.id,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
  }),
  states: createEntityAdapter<i.IState>({
    selectId: x => x.id,
    sortComparer: (a, b) => a.abbreviation.localeCompare(b.abbreviation)
  }),
  warehouses: createEntityAdapter<i.IWarehouse>({
    selectId: x => x.warehouseId,
    sortComparer: (a, b) => a.name.localeCompare(b.name)
  })
};
