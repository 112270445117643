import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

interface ILoginErrorServerErrorBodyProps {}

let LoginErrorServerErrorBody: React.ComponentType<ILoginErrorServerErrorBodyProps> = () => {
  return (
    <>
      <FontAwesomeIcon className="text-danger" icon={faExclamationTriangle} size="5x" />
      <h3>
        <strong>Something went wrong...</strong>
      </h3>
      <h4>There was a problem authenticating you.</h4>
      <h5>An attempt has been made to log the error. Sorry for the inconvenience.</h5>
    </>
  );
};

LoginErrorServerErrorBody = React.memo(LoginErrorServerErrorBody);
export { LoginErrorServerErrorBody };
