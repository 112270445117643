import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { dpProductFamilySelectors } from '../../lookups';
import { ICatalogFilter, IItem } from '../catalogInterfaces';

const pageSize = 10;

export const fetchCatalogThunk = createAsyncThunk(
  'fetchCatalog',
  async (
    { page, searchString, productFamilyId, orderBy, orderByDirection }: ICatalogFilter,
    { rejectWithValue, getState }
  ) => {
    const state = getState() as RootState;
    const productFamilies = dpProductFamilySelectors.selectAll(state);

    const skip = (page - 1) * pageSize;

    let url = `/Products/Catalog?skip=${skip}&take=${pageSize}&orderBy=${orderBy}&orderByDirection=${orderByDirection}`;

    if (searchString) {
      url += `&searchTerm=${encodeURIComponent(searchString)}`;
    }

    if (productFamilyId) {
      const ids = productFamilies.find(x => x.id === productFamilyId)?.productFamilyIds ?? [];

      url += `&${ids.map(x => `productFamilies=${x}`).join('&')}`;
    }

    const results = await executeApiRequest<{ items: IItem[]; totalItems: number }>(() => httpService.get(url));

    if (results.requestFailed) {
      return rejectWithValue('Failed to fetch catalog');
    }

    return results.value;
  }
);
