import { createAsyncThunk } from '@reduxjs/toolkit';
import { INewsFeedPost } from '..';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';

export const fetchNewsFeedPostThunk = createAsyncThunk(
  'fetchNewsFeedPost',
  async (postId: number, { rejectWithValue }) => {
    const response = await executeApiRequest<INewsFeedPost>(() => httpService.get(`/NewsFeed?postId=${postId}`));

    if (response.requestFailed) {
      return rejectWithValue(`Failed to fetch news feed post ${postId}`);
    }

    return response.value;
  }
);
