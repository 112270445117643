import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { IProfileApiResponse } from '../profileInterfaces';

export const fetchProfileThunk = createAsyncThunk('profile/fetch', async (_, { rejectWithValue }) => {
  const response = await executeApiRequest<IProfileApiResponse>(() => httpService.get(`/Dealers/Profile`));

  if (response.requestFailed) {
    return rejectWithValue('Failed to fetch dealer profile');
  }

  return response.value;
});
