import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from 'react';
import { checkAuthTokensThunk } from './ducks/auth';
import { useAppDispatch, useAppSelector } from './hooks';
import { SplashPage } from './layout/SplashPage';
import { LoginPage } from './login/LoginPage';

const MainPage = React.lazy(() => import(/* webpackChunkName: 'MainPage' */ './layout/MainPage'));

interface IRootProps {}

let Root: React.ComponentType<IRootProps> = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);
  const { appInitialized, appInitializing } = useAppSelector(state => state.core);

  React.useEffect(() => {
    dispatch(checkAuthTokensThunk());
  }, [dispatch]);

  if (appInitializing) {
    return <SplashPage />;
  }

  return appInitialized && isLoggedIn ? (
    <React.Suspense fallback={<h1>Loading</h1>}>
      <MainPage />
    </React.Suspense>
  ) : (
    <LoginPage />
  );
};

Root = React.memo(Root);
export { Root };
