import { createAsyncThunk } from '@reduxjs/toolkit';
import { httpService } from '../../..';
import { executeApiRequest } from '../../../utils/asyncUtils';
import { coreActions } from '../../core';
import { IPreSeasonCart } from '../preSeasonInterfaces';

export const rejectPreSeasonOrderThunk = createAsyncThunk(
  'preSeason/rejectOrder',
  async (cartId: number, { dispatch, rejectWithValue }) => {
    dispatch(coreActions.toggleWorkingOnItDialog());
    const response = await executeApiRequest<IPreSeasonCart>(() => httpService.delete(`/Preseason/${cartId}/Reject`));
    dispatch(coreActions.toggleWorkingOnItDialog());

    if (response.requestFailed) {
      return rejectWithValue(`Failed to reject pre-season order for cart ${cartId}`);
    }

    return response.value;
  }
);
